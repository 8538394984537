<template>
  <div id="quote-view" class="pa-sm-6">
    <v-alert v-if="$vuetify.breakpoint.xsOnly && !order" type="info" class="caption"
      >You are viewing the mobile version of your quote. Please view on a larger screen to access the visual and place
      your order.</v-alert
    >

    <!-- HEADER -->
    <v-container fluid class="mb-4">
      <v-row class="align-baseline mb-2 mb-sm-8">
        <v-col cols="12" sm="6" class="order-2 order-sm-1 text-center text-sm-left">
          <h1 class="text-">{{ order ? 'Order' : 'Quotation' }}</h1>
        </v-col>
        <v-col cols="12" sm="6" class="order-1 order-sm-2 text-center text-sm-right">
          <img src="@/assets/final-logo-tight.png" alt="Plykea" :width="$vuetify.breakpoint.xs ? '30%' : '200px'" />
        </v-col>
      </v-row>

      <v-row v-if="order"> </v-row>

      <v-row>
        <v-col cols="6" class="py-1 order-sm-1">
          <div class="caption text--secondary">
            {{ order ? 'Order Number' : 'Date Created' }}
          </div>
        </v-col>
        <v-col cols="6" class="py-1 order-sm-3">
          <div class="body-2 text-right text-sm-left">
            {{
              order
                ? order.orderNumber || order.xeroInvoiceNumber
                : $moment($utils.ensureFirestoreTimestamp(version.pricing.dateCreated).toDate()).format('Do MMM YYYY')
            }}
          </div>
        </v-col>
        <v-col cols="6" class="py-1 order-sm-5">
          <div class="caption text--secondary">
            {{ order ? 'Created' : 'Expires' }}
          </div>
        </v-col>
        <v-col cols="6" class="py-1 order-sm-7">
          <div class="body-2 text-right text-sm-left">
            {{
              order
                ? $moment.unix(order.dateCreated.seconds).format('Do MMM YYYY')
                : $moment($utils.ensureFirestoreTimestamp(version.pricing.dateCreated).toDate())
                    .add(30, 'days')
                    .format('Do MMM YYYY')
            }}
          </div>
        </v-col>
        <v-col cols="6" class="py-1 order-sm-2">
          <div class="caption text--secondary text-left text-sm-right">
            Prepared By
          </div>
        </v-col>
        <v-col cols="6" class="py-1 text-right order-sm-4">
          <div class="body-2">{{ version.creatorEmail }}</div>
        </v-col>
        <v-col cols="6" class="py-1 order-sm-6">
          <div class="caption text--secondary text-left text-sm-right">
            Prepared For
          </div>
        </v-col>
        <v-col cols="6" class="py-1 text-right order-sm-8">
          <div class="body-2">{{ customerEmail }}</div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="caption hidden-xs-only pb-0">
      <v-row>
        <v-col cols="8">Item</v-col>
        <v-col v-if="discountApplied" cols="1">Price</v-col>
        <v-col v-if="discountApplied" cols="1">Discount</v-col>
        <v-spacer />
        <v-col cols="1" class="text-right">Subtotal</v-col>
      </v-row>
    </v-container>

    <v-expansion-panels>
      <!-- FRONTS / COVER PANELS-->
      <v-expansion-panel
        v-for="material in requiredMaterialInfo"
        :name="material.id"
        :key="material.id"
        :disabled="$vuetify.breakpoint.xsOnly || material.thickness == 4"
      >
        <v-expansion-panel-header class="px-0 py-2 py-sm-4" hide-actions>
          <v-container fluid class="py-2">
            <v-row wrap>
              <v-col :cols="discountApplied ? 10 : 8" sm="8">
                <div class="text-sm-truncate text-body-2 text-sm-body-1">
                  <v-icon v-if="material.thickness != 4" class="hidden-xs-only mr-1">mdi-chevron-down </v-icon
                  >{{ material.thickness == 4 ? 'Handle Inserts in ' : 'Fronts, cover panels and plinths in ' }}
                  {{ material.colourCode }} {{ material.label }} {{ material.thickness }}mm{{
                    material.unfinished ? ' (unfinished)' : ''
                  }}
                  <span v-if="material.thickness != 4" class="hidden-xs-only accent--text"
                    >(click for itemised list)</span
                  >
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
                <div class="hidden-sm-and-up caption text--secondary">
                  Price
                </div>
                <div>
                  {{
                    version.pricing.fronts[material.id]
                      ? $numeral(version.pricing.fronts[material.id].total).format('$0,0.00')
                      : $numeral(0).format('$0,0.00')
                  }}
                </div>
              </v-col>
              <v-col v-if="version.discount > 0 || discountApplied" cols="1">
                <div class="hidden-sm-and-up caption text--secondary">
                  Discount
                </div>
                <div>{{ version.discount ? version.discount : 0 }}%</div>
              </v-col>
              <v-spacer />
              <v-col cols="4" sm="1" class="text-right">
                <div class="hidden-sm-and-up caption text--secondary">
                  Subtotal
                </div>
                <div>
                  {{
                    version.pricing.fronts[material.id]
                      ? $numeral(version.pricing.fronts[material.id].total * (1 - version.discount / 100)).format(
                          '$0,0.00'
                        )
                      : $numeral(0).format('$0,0.00')
                  }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-header>

        <v-expansion-panel-content v-if="$vuetify.breakpoint.smAndUp">
          <v-container fluid class="pa-0">
            <v-divider />
            <v-row class="caption">
              <v-col cols="1">Loc</v-col>
              <v-col cols="3">Item</v-col>
              <v-col cols="5">Feature / Handle</v-col>
              <v-col cols="2" class="text-truncate">Size ({{ quote.region == 'us' ? 'inch' : 'mm' }})</v-col>
              <v-col cols="1" class="text-right">Qty</v-col>
            </v-row>
            <v-divider class="mb-4" />

            <v-row
              v-for="front in frontsQuoteList"
              v-if="front.material == material.id"
              :key="front.id"
              class="caption"
            >
              <v-col cols="1" class="text-truncate">{{ front.itemNum }}</v-col>
              <v-col cols="3" class="text-truncate"
                >{{ front.specialLabel ? front.specialLabel : productData.getFrontTypeLabel(front.frontType)
                }}{{
                  front.joiningPos && ['top', 'mid', 'bot'].includes(front.joiningPos)
                    ? ' (' + getPositionName(front.joiningPos) + ')'
                    : ''
                }}{{ getMaterialSides(front.material) == 1 ? ' [Single-Sided]' : '' }}</v-col
              >
              <v-col cols="5" class="text-truncate"
                >{{ front.handle ? getHandleName(front.handle) + ' Handle' : 'N/A' }}
              </v-col>
              <v-col cols="2" class="text-truncate"
                >{{ quote.region == 'us' ? $utils.mmToInch(front.w) : front.w }}
                x
                {{ quote.region == 'us' ? $utils.mmToInch(front.h) : front.h }}
              </v-col>
              <v-col cols="1" class="text-right">{{ front.qty }}</v-col>
            </v-row>

            <v-row
              v-for="coverPanel in version.coverPanels"
              v-if="coverPanel.material == material.id"
              :key="coverPanel.id"
              class="caption"
            >
              <v-col cols="1" class="text-truncate">{{ coverPanel.itemNum }}</v-col>
              <v-col cols="3" class="text-truncate"
                >{{ productData.getCoverPanelTypeLabel(coverPanel.coverPanelType)
                }}{{ getMaterialSides(coverPanel.material) == 1 ? ' [Single-Sided]' : '' }}</v-col
              >
              <v-col cols="5">{{
                coverPanel.lighting
                  ? `${coverPanel.lighting}mm Lighting ${
                      coverPanel.lightingRef === 'r'
                        ? `from the Right ${coverPanel.lightingOffset}mm`
                        : coverPanel.lightingRef === 'l'
                        ? `from the Left ${coverPanel.lightingOffset}mm`
                        : 'Centered'
                    } `
                  : coverPanel.coverPanelType === 'v-plinth' && coverPanel.vent
                  ? `${coverPanel.vent}mm Vent from the ${coverPanel.ventRef === 'r' ? 'Right' : 'Left'} ${
                      coverPanel.ventOffset
                    }mm`
                  : 'n/a'
              }}</v-col>
              <v-col cols="2" class="text-truncate"
                >{{ quote.region == 'us' ? $utils.mmToInch(coverPanel.w) : coverPanel.w }}
                x
                {{ quote.region == 'us' ? $utils.mmToInch(coverPanel.h) : coverPanel.h }}</v-col
              >
              <v-col cols="1" class="text-right">{{ coverPanel.qty }} </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- SPACER PANELS -->
      <v-expansion-panel v-if="version.spacerPanels.length > 0" :disabled="$vuetify.breakpoint.xsOnly">
        <v-expansion-panel-header class="px-0 py-2 py-sm-4" hide-actions>
          <v-container fluid class="py-2">
            <v-row wrap>
              <v-col :cols="discountApplied ? 10 : 8" sm="8">
                <div class="text-sm-truncate text-body-2 text-sm-body-1">
                  <v-icon class="hidden-xs-only mr-1">mdi-chevron-down</v-icon>Spacer Panels
                  <span class="accent--text hidden-xs-only">(click for itemised list)</span>
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
                <div class="caption hidden-sm-and-up caption text--secondary">
                  Price
                </div>
                <div class="body-2">
                  {{ $numeral(version.pricing.spacerPanels.totalPrice).format('$0,0.00') }}
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
                <div class="caption hidden-sm-and-up caption text--secondary">
                  Discount
                </div>
                <div class="body-2">{{ version.discount ? version.discount : 0 }}%</div>
              </v-col>
              <v-spacer />
              <v-col cols="4" sm="1" offset-sm1 class="text-right">
                <div class="hidden-sm-and-up caption text--secondary">
                  Subtotal
                </div>
                <div>
                  {{
                    $numeral(version.pricing.spacerPanels.totalPrice * (1 - version.discount / 100)).format('$0,0.00')
                  }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-header>

        <v-expansion-panel-content v-if="$vuetify.breakpoint.smAndUp">
          <v-divider />
          <v-row class="caption">
            <v-col cols="2">Location</v-col>
            <v-col cols="2">Material</v-col>
            <v-col cols="2">Length ({{ quote.region == 'us' ? 'inch' : 'mm' }})</v-col>
            <v-spacer />
            <v-col cols="2" class="text-right">Qty</v-col>
          </v-row>
          <v-divider class="mb-4" />

          <v-row row v-for="(spacer, key) in version.spacerPanels" :key="key" class="caption">
            <v-col cols="2">{{ spacer.location }}</v-col>
            <v-col cols="2">{{ productData.getSpacerMaterial(spacer.material).label }}</v-col>
            <v-col cols="2">{{ quote.region == 'us' ? $utils.mmToInch(spacer.l) : spacer.l }}</v-col>
            <v-spacer />
            <v-col cols="2" class="text-right">{{ spacer.qty }}</v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- WORKTOPS -->
      <v-expansion-panel v-if="version.worktops.length > 0" :disabled="$vuetify.breakpoint.xsOnly">
        <v-expansion-panel-header class="px-0 py-2 py-sm-4" hide-actions>
          <v-container fluid class="py-2">
            <v-row wrap>
              <v-col :cols="discountApplied ? 10 : 8" sm="8">
                <div class="text-sm-truncate text-body-2 text-sm-body-1">
                  <v-icon class="hidden-xs-only mr-1">mdi-chevron-down</v-icon>Worktops
                  <span class="accent--text hidden-xs-only">(click for itemised list)</span>
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
                <div class="caption hidden-sm-and-up caption text--secondary">
                  Price
                </div>
                <div class="body-2">
                  {{ $numeral(version.pricing.worktops.total).format('$0,0.00') }}
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discountWorktops > 0 || discountApplied">
                <div class="caption hidden-sm-and-up caption text--secondary">
                  Discount
                </div>
                <div class="body-2">{{ version.discountWorktops ? version.discountWorktops : 0 }}%</div>
              </v-col>
              <v-spacer />
              <v-col cols="4" sm="1" offset-sm1 class="text-right">
                <div class="hidden-sm-and-up caption text--secondary">
                  Subtotal
                </div>
                <div>
                  {{
                    $numeral(version.pricing.worktops.total * (1 - version.discountWorktops / 100)).format('$0,0.00')
                  }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-header>

        <v-expansion-panel-content v-if="$vuetify.breakpoint.smAndUp">
          <v-divider />
          <v-row class="caption">
            <v-col cols="2">Section</v-col>
            <v-col cols="2">Dimensions ({{ quote.region == 'us' ? 'inch' : 'mm' }})</v-col>
            <v-col cols="2">Finishing</v-col>
            <v-col cols="2">Radius (mm)</v-col>
            <v-col cols="2">Material</v-col>
            <v-col cols="2" class="text-right">Colour</v-col>
          </v-row>
          <v-divider class="mb-4" />

          <v-row v-for="worktop in version.worktops" :key="worktop.id" class="caption">
            <v-col cols="12">
              <v-row v-for="section in worktop.sections" :key="section.id">
                <v-col cols="2">{{ section.name }}{{ section.dogleg ? ' (Dogleg)' : '' }}</v-col>
                <v-col cols="2"
                  >{{
                    quote.region == 'us'
                      ? $utils.mmToInch(worktop.thickness)
                      : section.coreThickness || worktop.thickness
                  }}
                  x
                  {{ quote.region == 'us' ? $utils.mmToInch(section.l) : section.l }}
                  x
                  {{ quote.region == 'us' ? $utils.mmToInch(section.d) : section.d }}
                  {{ constructDoglegDimension(section) }}
                </v-col>
                <v-col cols="2">{{ constructFinishedEdgesString(section) }}</v-col>
                <v-col cols="2">{{ constructRadiusString(section) }}</v-col>
                <v-col cols="2">{{ worktop.material }} {{ worktop.faces }}</v-col>
                <v-col cols="2" class="text-right"
                  >{{ findWorktopColourName(worktop) }} - {{ worktop.colourCode }}</v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- URTILS -->
      <v-expansion-panel v-if="version.urtils && version.urtils.length > 0" :disabled="$vuetify.breakpoint.xsOnly">
        <v-expansion-panel-header class="px-0 py-2 py-sm-4" hide-actions>
          <v-container fluid class="py-2">
            <v-row wrap>
              <v-col :cols="discountApplied ? 10 : 8" sm="8">
                <div class="text-sm-truncate text-body-2 text-sm-body-1">
                  <v-icon class="hidden-xs-only mr-1">mdi-chevron-down</v-icon>Urtils<span
                    class="hidden-xs-only accent--text"
                  >
                    (click for itemised list)</span
                  >
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
                <div class="caption hidden-sm-and-up caption text--secondary">
                  Price
                </div>
                <div class="body-2">
                  {{ $numeral(version.pricing.urtils.total).format('$0,0.00') }}
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
                <div class="caption hidden-sm-and-up caption text--secondary">
                  Discount
                </div>
                <div class="body-2">0%</div>
              </v-col>
              <v-spacer />
              <v-col cols="4" sm="1" class="text-right">
                <div class="caption text--secondary hidden-sm-and-up">
                  Subtotal
                </div>
                <div>
                  {{ $numeral(version.pricing.urtils.total).format('$0,0.00') }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-header>

        <v-expansion-panel-content v-if="$vuetify.breakpoint.smAndUp">
          <v-divider />
          <v-row class="caption text-truncate">
            <v-col cols="1">Name + Type</v-col>
            <v-col cols="2">Dimensions W/H/D</v-col>
            <v-col cols="1">Compartments</v-col>
            <v-col cols="2">Back</v-col>
            <v-col cols="2">Door 1</v-col>
            <v-col cols="2">Door 2</v-col>
            <v-col cols="1" class="text-right">Lighting</v-col>
            <v-col cols="1" class="text-right">Shelving</v-col>
          </v-row>
          <v-divider class="mb-4" />

          <v-row v-for="urtil in version.urtils" :key="urtil.id" class="caption">
            <v-col cols="1" class="text-wrap"
              >{{ urtil.name }} - {{ productData.getUrtilType(urtil.type).label.split(' ')[0] }} /
              {{ getMaterialName(urtil.carcass).split('/')[0]
              }}{{ urtil.preAssembled ? ' (Pre-Assembled)' : urtil.trimming ? ' (Trim-Fit)' : ' (Flat-Packed)' }}</v-col
            >
            <v-col cols="2" class="text-truncate">{{ urtil.w }}mm / {{ urtil.h }}mm / {{ urtil.d }}mm</v-col>
            <v-col cols="1" class="text-truncate">{{ urtil.compartments }}</v-col>
            <v-col cols="2" class="text-truncate">{{
              urtil.back ? getMaterialName(urtil.back).split('/')[0] : 'N/A'
            }}</v-col>
            <v-col cols="2" class="text-truncate"
              >{{
                urtil.singleDoorSide && urtil.type == 's'
                  ? 'Right-Side '
                  : !urtil.singleDoorSide && urtil.type == 's'
                  ? 'Left-Side '
                  : ''
              }}{{ urtil.material1 && urtil.type !== 'o' ? getMaterialName(urtil.material1).split('/')[0] : 'N/A' }}
              {{ urtil.doorCoverageOne ? '[' + urtil.doorCoverageOne + ']' : '' }}</v-col
            >
            <v-col cols="2" class="text-truncate"
              >{{ urtil.material2 && urtil.type == 't' ? getMaterialName(urtil.material2).split('/')[0] : 'N/A' }}
              {{ urtil.doorCoverageTwo ? '[' + urtil.doorCoverageTwo + ']' : '' }}</v-col
            >
            <v-col cols="1" class="text-right">{{ urtil.lighting || 'N/A' }}</v-col>
            <v-col cols="1" class="text-right">{{
              urtil.shelving ? urtil.shelving + ' x ' + urtil.shelvingOptions.qty * urtil.compartments : 'N/A'
            }}</v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- PANTRIES -->
      <v-expansion-panel v-if="version.pantries && version.pantries.length > 0" :disabled="$vuetify.breakpoint.xsOnly">
        <v-expansion-panel-header class="px-0 py-2 py-sm-4" hide-actions>
          <v-container fluid class="py-2">
            <v-row wrap>
              <v-col :cols="discountApplied ? 10 : 8" sm="8">
                <div class="text-sm-truncate text-body-2 text-sm-body-1">
                  <v-icon class="hidden-xs-only mr-1">mdi-chevron-down</v-icon
                  >{{ version.pantries.length === 1 ? 'Pantry' : 'Pantries'
                  }}<span class="hidden-xs-only accent--text"> (click for itemised list)</span>
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
                <div class="caption hidden-sm-and-up caption text--secondary">
                  Price
                </div>
                <div class="body-2">
                  {{ $numeral(version.pricing.pantries.total).format('$0,0.00') }}
                </div>
              </v-col>
              <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
                <div class="caption hidden-sm-and-up caption text--secondary">
                  Discount
                </div>
                <div class="body-2">0%</div>
              </v-col>
              <v-spacer />
              <v-col cols="4" sm="1" class="text-right">
                <div class="caption text--secondary hidden-sm-and-up">
                  Subtotal
                </div>
                <div>
                  {{ $numeral(version.pricing.pantries.total).format('$0,0.00') }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-header>

        <v-expansion-panel-content v-if="$vuetify.breakpoint.smAndUp">
          <v-divider />
          <v-row class="caption text-truncate">
            <v-col cols="1">Name</v-col>
            <v-col cols="3">Type</v-col>
            <v-col cols="2">Dimensions W/H/D</v-col>
            <v-col cols="1">Back</v-col>
            <v-col cols="2">Base</v-col>
            <v-col cols="1" class="text-right">Lighting</v-col>
            <v-col cols="1" class="text-right">Adj. Shelves</v-col>
            <v-col cols="1" class="text-right">Fixed Shelf</v-col>
          </v-row>
          <v-divider class="mb-4" />

          <v-row v-for="pantry in version.pantries" :key="pantry.id" class="caption">
            <v-col cols="1" class="text-truncate">{{ pantry.name }}</v-col>
            <v-col cols="3" class="text-truncate"
              >{{ getMaterialName(pantry.carcass).split('/')[0] }} Carcass [{{
                productData.getPantryHinge(pantry.pantryHinge).label
              }}] - {{ productData.getPantryType(pantry.pantryType).label
              }}{{ pantry.flatPack ? ' (Flat-Packed)' : '' }}
            </v-col>
            <v-col cols="2" class="text-truncate"
              >{{ pantry.w }}mm / {{ pantry.h }}mm /
              {{ pantry.pantryHinge === 'o' ? pantry.d + 14 : pantry.d }}mm</v-col
            >
            <v-col cols="1" class="text-truncate">{{
              pantry.back ? getMaterialName(pantry.back).split('/')[0] : 'N/A'
            }}</v-col>
            <v-col cols="2" class="text-truncate"
              >{{ pantry.base ? getMaterialName(pantry.base).split('/')[0] : 'N/A'
              }}{{ getMaterialSides(pantry.base) == 1 ? ' [Single-Sided]' : '' }}</v-col
            >
            <v-col cols="1" class="text-right">{{
              pantry.lighting ? `2 of W${productData.getPantryLightingWidth(pantry.w)}mm` : 'N/A'
            }}</v-col>
            <v-col cols="1" class="text-right">{{
              `2 of D${productData.getPantryShelfDepth(pantry.d, 'adj')}mm`
            }}</v-col>
            <v-col cols="1" class="text-right">{{
              `1 of D${productData.getPantryShelfDepth(pantry.d, 'fixed')}mm`
            }}</v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-container fluid class="py-2">
      <!-- ADDITIONAL ITEMS -->
      <v-row
        v-for="item in version.additionalItems"
        v-if="!(item.excludeFromPacked && customerView)"
        :key="item.id"
        class="py-2 py-sm-4"
        :style="{ 'border-top': 'solid 1px #eee' }"
      >
        <v-col :cols="discountApplied ? 10 : 8" sm="8">
          <div class="text-sm-truncate text-body-2 text-sm-body-1">
            {{ item.description }}
          </div>
        </v-col>
        <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
          <div class="caption text--secondary hidden-sm-and-up">Price</div>
          <div class="body-2">
            {{ $numeral(parseFloat(item.value)).format('$0,0.00') }}
          </div>
        </v-col>
        <v-col cols="4" sm="1" v-if="version.discount > 0 || discountApplied">
          <div class="caption text--secondary hidden-sm-and-up">Discount</div>
          <div class="body-2">{{ item.discount ? item.discount : 0 }}%</div>
        </v-col>
        <v-spacer />
        <v-col cols="4" sm="1" offset-sm1 class="text-right">
          <div class="caption text--secondary hidden-sm-and-up">Subtotal</div>
          <div class="body-2">
            {{ $numeral(parseFloat(item.value * (1 - item.discount / 100))).format('$0,0.00') }}
          </div>
        </v-col>
      </v-row>

      <!-- Extra Info (Handle, Backs, Bases, Doors)  -->
      <v-row class="py-2 py-sm-4" :style="{ 'border-top': 'solid 1px #eee' }" v-if="summaryItems.length > 0">
        <v-col cols="12" sm="4">
          <div class="caption text--secondary">Detailed Summary</div>
          <div class="text-sm-truncate body-2 pt-2" v-for="item in summaryItems">
            {{ item.type }}{{ item.materialInfo }}
          </div>
        </v-col>
      </v-row>

      <!-- TOTALS -->
      <v-row v-if="quote.region === 'uk'" class="totals">
        <v-col cols="12" sm="4" offset-sm="8">
          <v-divider class="pt-8" />
          <v-row>
            <v-col cols="6">Subtotal</v-col>
            <v-col cols="6" class="text-right">{{ $numeral(version.pricing.subtotal).format('$0,0.00') }}</v-col>
          </v-row>
          <v-row
            v-if="
              version.pricing.discountedSubtotal >= 0 &&
                $utils.toFixedNumber(version.pricing.subtotal, 2) !==
                  $utils.toFixedNumber(version.pricing.discountedSubtotal, 2)
            "
          >
            <v-col cols="6">Less Discounts</v-col>
            <v-col xcols="6" s6 class="text-right">{{
              $numeral(version.pricing.discountedSubtotal).format('$0,0.00')
            }}</v-col>
          </v-row>
          <v-row v-else-if="!version.pricing.discountedSubtotal && version.discount > 0">
            <v-col cols="6">Less {{ version.discount }}% discount</v-col>
            <v-col cols="6" class="text-right">{{
              $numeral(version.pricing.subtotal * (1 - version.discount / 100)).format('$0,0.00')
            }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="6">Delivery</v-col>
            <v-col cols="6" class="text-right">
              {{ version.pricing.shipping === 0 ? 'Free' : $numeral(version.pricing.shipping).format('$0,0.00') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">VAT</v-col>
            <v-col cols="6" class="text-right">{{ $numeral(version.pricing.vatAmount).format('$0,0.00') }}</v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row>
            <v-col cols="6"><b>TOTAL</b></v-col>
            <v-col cols="6" class="text-right">
              <b v-if="version.foc">Free of charge</b>
              <b v-else>{{ $numeral(version.pricing.total).format('$0,0.00') }}</b>
            </v-col>
          </v-row>
          <v-divider class="mt-4" />
        </v-col>
      </v-row>

      <v-row v-else class="totals">
        <v-col cols="12" sm="4" offset-sm="8">
          <v-divider class="pt-8" />
          <v-row>
            <v-col cols="6">Subtotal</v-col>
            <v-col cols="6" class="text-right">{{ $numeral(version.pricing.subtotal).format('$0,0.00') }}</v-col>
          </v-row>
          <v-row v-if="version.discount > 0">
            <v-col cols="6">Subtotal</v-col>
            <v-col cols="6" class="text-right">{{ $numeral(version.pricing.subtotal).format('$0,0.00') }}</v-col>
          </v-row>
          <v-row
            v-if="version.pricing.discountedSubtotal && version.pricing.subtotal !== version.pricing.discountedSubtotal"
          >
            <v-col cols="6">Less Discounts</v-col>
            <v-col cols="6" class="text-right">{{
              $numeral(version.pricing.discountedSubtotal).format('$0,0.00')
            }}</v-col>
          </v-row>
          <v-row v-else-if="!version.pricing.discountedSubtotal && version.discount > 0">
            <v-col cols="6">Less {{ version.discount }}% discount</v-col>
            <v-col cols="6" class="text-right">{{
              $numeral(version.pricing.subtotal * (1 - version.discount / 100)).format('$0,0.00')
            }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="6">Delivery</v-col>
            <v-col cols="6" class="text-right">
              {{ version.pricing.shipping === 0 ? 'Free' : $numeral(version.pricing.shipping).format('$0,0.00') }}
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row class="total">
            <v-col cols="6"><b>TOTAL (excluding taxes)</b></v-col>
            <v-col cols="6" class="text-right">
              <b v-if="version.foc">Free of charge</b>
              <b v-else>{{ $numeral(version.pricing.total).format('$0,0.00') }}</b>
            </v-col>
          </v-row>
          <v-row class="total" v-if="version.pricing.salesTax && !version.foc">
            <v-col cols="6"><b>Sales Tax</b></v-col>
            <v-col cols="6" class="text-right">
              <b v-if="version.foc">Free of charge</b>
              <b v-else>{{ $numeral(version.pricing.salesTax.amount_to_collect).format('$0,0.00') }}</b>
            </v-col>
          </v-row>
          <v-divider class="mt-4" />
        </v-col>
      </v-row>

      <!-- Payments -->
      <v-row v-if="order && order.payments.length > 0">
        <v-col cols="12" sm="4" offset-sm="8">
          <v-row>
            <v-col cols="6">Payments</v-col>
            <v-col cols="6" class="text-right">
              <div class="pb-4" v-for="(payment, index) in order.payments" :key="index">
                {{ $numeral(payment.amount).format('$0,0.00') }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="mb-4" />
          <div v-if="quote.region !== 'us'">
            <v-row class="total">
              <v-col cols="6"><b>Balance Due</b></v-col>
              <v-col cols="6" class="text-right"
                ><b>{{ $numeral(balanceDue).format('$0,0.00') }}</b></v-col
              >
            </v-row>
            <v-divider class="mt-4" />
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="
          version.notes ||
            version.earlySpacers ||
            version.earlyWorktops ||
            worktopNote.hasDogleg ||
            worktopNote.hasUpstand ||
            quote.region === 'eu'
        "
      >
        <v-col cols="12" sm="4">
          <div>Notes</div>
          <ul>
            <li v-if="quote.region === 'eu' && estimatedImportVat">
              Estimated import tax
              {{ $numeral(estimatedImportVat).format('$0,0.00') }}
            </li>
            <li v-if="version.notes">{{ version.notes }}</li>
            <li v-if="worktopNote.hasDogleg">
              Worktop(s) include dogleg section
            </li>
            <li v-if="worktopNote.hasUpstand">
              Worktop(s) include upstand section
            </li>
            <li v-if="version.earlySpacers">
              Spacer panels to be delivered early
            </li>
            <li v-if="version.earlyWorktops">
              Worktops to be delivered early
            </li>
          </ul>
        </v-col>
      </v-row>

      <v-row v-if="version.changeStatement">
        <v-col cols="12" sm="4">
          <div>Changes Made</div>
          <ul>
            <li>{{ version.changeStatement }}</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import productData from '@/js/productData'
import store from '@/store'

export default {
  name: 'QuoteView',
  props: ['quote', 'order', 'version', 'customerEmail', 'customerView'],
  data() {
    return {
      productData: productData,
      estimatedImportVat: null
    }
  },
  async created() {
    var currency = !this.version.currency ? 'GBP' : this.version.currency
    this.$numeral.locale(currency)
    this.runLegacyCheck()

    if (this.quote.region === 'eu') {
      const shippingZone = await this.$db
        .collection('shippingZones')
        .doc(this.version.deliveryLocation)
        .get()
      if (shippingZone.exists) {
        this.estimatedImportVat = this.version.pricing.total * (shippingZone.data().importVat / 100)
      }
    }
  },
  watch: {
    version() {
      var currency = !this.version.currency ? 'GBP' : this.version.currency
      this.$numeral.locale(currency)
    }
  },
  computed: {
    frontsQuoteList() {
      const frontsQuoteListArray = []
      this.version.fronts.forEach(front => {
        if (front.frontType.includes('-') && front.frontType.split('-')[2] == 'cnr') {
          // create special front Obj for door, narrowFiller & wideFiller
          let cabinet = front.frontType.split('-')[1]

          let cnrFrontArray = [
            {
              frontType: front.frontType,
              w: front.w,
              h: front.h,
              handle: front.handle,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid
            },
            {
              frontType: front.frontType,
              w:
                this.quote.region == 'uk' && cabinet == 'w'
                  ? 100
                  : this.quote.region == 'uk' && cabinet != 'w'
                  ? 60
                  : this.quote.region == 'us' && cabinet == 'w'
                  ? 102
                  : 51,
              h: front.h,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid + '-nf',
              specialLabel: 'Narrow corner filler'
            },
            {
              frontType: front.frontType,
              w:
                this.quote.region == 'uk' && cabinet == 'w'
                  ? 120
                  : this.quote.region == 'uk' && cabinet != 'w'
                  ? 80
                  : this.quote.region == 'us' && cabinet == 'w'
                  ? 127
                  : 70,
              h: front.h,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid + '-wf',
              specialLabel: 'Wide corner filler'
            }
          ]
          cnrFrontArray.forEach(cnrUnit => frontsQuoteListArray.push(cnrUnit))
        } else {
          let frontObj = {
            frontType: front.frontType,
            w: front.w,
            h: front.h,
            handle: front.handle,
            itemNum: front.itemNum,
            material: front.material,
            qty: front.qty,
            id: front.uid,
            joiningPos: front?.joiningPos,
            joiningDouble: front?.joiningDouble
          }
          frontsQuoteListArray.push(frontObj)
        }
      })
      return frontsQuoteListArray
    },
    discountApplied() {
      var b = false
      if (this.version.discount > 0 || this.version.discountWorktops > 0) {
        b = true
      }
      // check additional items
      if (this.version.additionalItems) {
        for (var i = 0; i < this.version.additionalItems.length; i++) {
          if (this.version.additionalItems[i].discount > 0) {
            b = true
          }
        }
      }
      return b
    },
    balanceDue() {
      if (!this.order) return null
      const total =
        this.order.region === 'us' && this.version.pricing.salesTax
          ? this.version.pricing.total + this.version.pricing.salesTax.amount_to_collect
          : this.version.pricing.total
      let paymentsTotal = 0
      for (const payment of this.order.payments) {
        paymentsTotal += payment.amount
      }
      return this.$utils.toFixedNumber(total - paymentsTotal, 2)
    },
    requiredMaterialInfo() {
      let materialRequired = this.version.materials.filter(material => {
        if (
          this.version.pricing?.fronts[material.id] &&
          this.version.pricing.fronts[material.id].total > 0 &&
          [24, 25, 36, 18, 4].includes(material.thickness)
        ) {
          return material
        }
      })
      return materialRequired
    },
    summaryItems() {
      let summaryItems = []
      let insertHandles = []
      let remainingHandles = []

      for (const material of this.version.materials) {
        // find handles
        if (material.thickness == 4 && this.version.handles) {
          let matchingHandles = this.version.handles.filter(handle => handle.insert == material.id)

          // reduce matchingHandles to only unique handles where if insert and value is the same then just keep one
          matchingHandles = matchingHandles.filter(
            (handle, index, self) =>
              index === self.findIndex(t => t.insert === handle.insert && t.value === handle.value)
          )
          matchingHandles.forEach(match => insertHandles.push(match))
        }

        // find Urtil Doors
        if (material.thickness == 6 && this.version.urtils) {
          let item = {
            materialInfo: '',
            type: ''
          }

          let matchingUrtilDoor = this.version.urtils.find(
            urtil => urtil.material1 == material.id || urtil.material2 == material.id
          )

          if (matchingUrtilDoor) {
            let materialLabel

            // catch glass door material
            if (['Reeded Glass Frosted Frosted', 'Reeded Glass Clear Clear'].includes(material.label)) {
              let lastIndex = material.label.lastIndexOf(' ')
              materialLabel = material.label.substring(0, lastIndex)
            } else {
              materialLabel = material.label
            }

            item.materialInfo = `${materialLabel}`
            item.type = `Urtil Door in `

            summaryItems.push(item)
          }
        }

        // find Urtil Backs
        if (material.thickness == 6 && this.version.urtils) {
          let item = {
            materialInfo: '',
            type: ''
          }
          let matchingUrtilBacks = this.version.urtils.find(urtil => urtil.back == material.id)

          if (matchingUrtilBacks) {
            item.materialInfo = `${material.label}`
            item.type = `Urtil Back in `

            summaryItems.push(item)
          }
        }

        // find Pantry Backs
        if (material.thickness == 6 && this.version.pantries) {
          let item = {
            materialInfo: '',
            type: ''
          }
          let matchingPantryBacks = this.version.pantries.find(pantry => pantry.back == material.id)

          if (matchingPantryBacks) {
            item.materialInfo = `${material.label}`
            item.type = 'Pantry Back in '

            summaryItems.push(item)
          }
        }

        // find Pantry Bases
        if (material.thickness == 18 && this.version.pantries) {
          let item = {
            materialInfo: '',
            type: ''
          }
          let matchingPantryLamBase = this.version.pantries.find(
            pantry => pantry.carcass != pantry.base && pantry.base == material.id
          )

          if (matchingPantryLamBase) {
            item.materialInfo = `${material.label}`
            item.type = `Pantry Base in `

            summaryItems.push(item)
          }
        }
      }

      if (insertHandles.length > 0 && this.version.handles) {
        // include insert handles
        insertHandles.forEach(handleType => {
          let item = {
            materialInfo: '',
            type: ''
          }
          let handleMaterial = this.version.materials.find(material => material.id == handleType.insert)
          item.materialInfo = ` in ${handleMaterial.label}`
          let handleTypeName = handleType.label.split('/')[0]
          item.type = handleTypeName.split(' - ')[1]
          summaryItems.push(item)
        })

        // find the rest of the handles
        remainingHandles = this.version.handles.filter(handle => !insertHandles.includes(handle) && !handle.insert)
      } else {
        remainingHandles = this.version.handles
      }

      // include non-insert handles
      if (remainingHandles.length > 0) {
        remainingHandles.forEach(handleType => {
          let item = {
            materialInfo: '',
            type: ''
          }
          item.materialInfo = ''
          item.type = handleType.label == 'None' ? 'Fronts with No Handle' : handleType.label
          summaryItems.push(item)
        })
      }

      // find joining brackets
      let joiningBracketCount = 0
      this.version.fronts.forEach(front => {
        if (front.joiningPos) {
          let handleType = this.version.handles.filter(handle => front.handle == handle.id)
          if (!['j', 'invisipull-short'].includes(handleType[0].value)) {
            switch (front.joiningPos) {
              case 'top':
              case 'bot':
                joiningBracketCount += front.qty
                break
              case 'mid':
                joiningBracketCount += front.qty * 2
                break
            }
          }
        }
      })
      if (joiningBracketCount > 0) {
        summaryItems.push({
          type: 'Joining Brackets x ' + joiningBracketCount,
          materialInfo: ''
        })
      }

      return summaryItems.reverse()
    },
    worktopNote() {
      let worktopNote = {
        hasDogleg: false,
        hasUpstand: false
      }
      for (const worktop of this.version.worktops) {
        let sectionDogleg = worktop.sections.filter(section => {
          if (section.dogleg) return true
        })
        let sectionUpstand = worktop.sections.filter(section => {
          if (section.customCoreThickness) return true
        })

        if (sectionDogleg.length > 0) worktopNote.hasDogleg = true
        if (sectionUpstand.length > 0) worktopNote.hasUpstand = true
      }

      return worktopNote
    }
  },
  methods: {
    getMaterialSides(code) {
      const mat = this.version.materials.find(material => {
        return code === material.id
      })
      return mat?.sides
    },
    getHandleName(id) {
      for (var i = 0; i < this.version.handles.length; i++) {
        if (this.version.handles[i].id == id) {
          return this.version.handles[i].label
        }
      }
    },
    getPositionName(pos) {
      let positionName
      switch (pos) {
        case 'top':
          positionName = 'Joined Top'
          break
        case 'mid':
          positionName = 'Joined Middle'
          break
        case 'bot':
          positionName = 'Joined Bottom'
          break
      }
      return positionName
    },
    getMaterialName(code) {
      for (var i = 0; i < this.version.materials.length; i++) {
        if (code == this.version.materials[i].id) {
          var m = this.version.materials[i]
          if (['Reeded Glass Frosted Frosted', 'Reeded Glass Clear Clear'].includes(m.label)) {
            let lastIndex = m.label.lastIndexOf(' ')
            return m.label.substring(0, lastIndex)
          }
          return m.colourCode ? m.label + ' / ' + m.colourCode : m.label
        }
      }
      return ''
    },
    constructDoglegDimension(section) {
      let doglegDimension = ''
      if (section.dogleg) {
        switch (this.quote.region) {
          case 'us':
            doglegDimension = ` (${this.$utils.mmToInch(section.li)} x ${this.$utils.mmToInch(section.di)})`
            break
          case 'uk':
            doglegDimension = ` (${section.li} x ${section.di})`
            break
        }
      }
      return doglegDimension
    },
    constructFinishedEdgesString(section) {
      let s = ''
      if (section.finished.front) s += 'F'
      if (section.finished.back) s += 'B'
      if (section.finished.left) s += 'L'
      if (section.finished.right) s += 'R'
      if (section.dogleg) {
        let dogLong,
          dogShort = ''
        if (section.finished.dogLong) {
          dogLong = 'X'
        }
        if (section.finished.dogShort) {
          dogShort = 'Y'
        }
        let finishedDoglegEdge = ` (${dogLong}${dogShort})`
        s += finishedDoglegEdge
      }
      return s
    },
    constructRadiusString(section) {
      if (!section.radii) return 'n/a'
      if (!section.dogleg) {
        return section.radii.bl + ' | ' + section.radii.br + ' | ' + section.radii.fr + ' | ' + section.radii.fl
      } else {
        return (
          section.radii.bl +
          ' | ' +
          section.radii.br +
          ' | ' +
          section.radii.fr +
          ' | (' +
          section.radii.fm +
          ' | ' +
          section.radii.i +
          ') | ' +
          section.radii.fl
        )
      }
    },
    runLegacyCheck() {
      for (var i = 0; i < this.version.fronts.length; i++) {
        if (this.version.fronts[i].frontType !== productData.updateFrontIfLegacy(this.version.fronts[i].frontType)) {
          this.updateLegacyFronts()
          break
        }

        let updateInfo = productData.updateJoinedFrontIfLegacy(this.version.fronts[i].frontType)
        // console.log('updateInfo', updateInfo)
        if (this.version.fronts[i].frontType !== updateInfo.value) {
          this.updateLegacyJoinedFronts()
          break
        }
      }

      this.version.urtils.forEach(urtil => {
        if (!urtil.compartments) {
          this.updateLegacyUrtil(urtil)
        }
      })

      if (this.version.discountWorktops === undefined) {
        this.version.discountWorktops = this.version.discount
      }
    },
    updateLegacyFronts() {
      var prevItem
      for (var i = 0; i < this.version.fronts.length; i++) {
        this.version.fronts[i].frontType = productData.updateFrontIfLegacy(this.version.fronts[i].frontType)

        // catch stacked appliance doors
        if (prevItem && prevItem.itemNum == this.version.fronts[i].itemNum) {
          if (prevItem.frontType == 'lhd-b-a' && this.version.fronts[i].frontType == 'lhd-b-a') {
            this.version.fronts[i].frontType = 'lhd-w-a'
          } else if (prevItem.frontType == 'rhd-b-a' && this.version.fronts[i].frontType == 'rhd-b-a') {
            this.version.fronts[i].frontType = 'rhd-w-a'
          }
        }
        prevItem = this.version.fronts[i]
      }
      this.$store.commit('openSnackbar', {
        snackbarText: 'Quote updated from legacy format'
      })
    },
    updateLegacyJoinedFronts() {
      // console.log(this.version.fronts)
      this.version.fronts.forEach((front, index) => {
        const checkedVal = productData.updateJoinedFrontIfLegacy(front.frontType)

        // catch qty > 1
        if (front.frontType !== checkedVal && front.qty > 1) {
          for (let i = 0; i < front.qty; i++) {
            let newFront = { ...front }
            newFront.itemNum += String.fromCharCode(97 + front.qty - 1 - i).toUpperCase()
            newFront.uid += String.fromCharCode(97 + front.qty - 1 - i).toUpperCase()
            newFront.qty = 1
            newFront.frontType = checkedVal.value
            newFront.joiningPos = checkedVal.joiningPos
            this.version.fronts.push(newFront)
          }
          this.version.fronts.splice(index, 1)

          this.$store.commit('openSnackbar', {
            snackbarText:
              'Legacy Quote had joined door with multiple QTY. The door has been split up to single QTY, please check manually!'
          })
        } else if (front.frontType !== checkedVal && front.qty == 1) {
          // update legacy joined values
          front.frontType = checkedVal.value
          front.joiningPos = checkedVal.joiningPos

          this.$store.commit('openSnackbar', {
            snackbarText: 'Legacy Quote with joined door has been updated'
          })
        }
      })
    },
    updateLegacyUrtil(urtil) {
      // if urtil has forceCentralSupport then need to add compartment!
      if (urtil.forceCenterSupport || urtil.w > 1000) {
        urtil.compartments = 2
        delete urtil.forceCenterSupport
        // if urtil has doors then set initial door coverage to match compartment
        if (urtil.type === 's') {
          urtil.doorCoverageOne = 1
          urtil.doorCoverageTwo = null
        } else if (urtil.type === 't') {
          urtil.doorCoverageOne = 1
          urtil.doorCoverageTwo = 1
        } else {
          urtil.doorCoverageOne = null
          urtil.doorCoverageTwo = null
        }
      } else {
        urtil.compartments = 1
        if (urtil.type === 's') {
          urtil.doorCoverageOne = 0.5
          urtil.doorCoverageTwo = null
        } else if (urtil.type === 't') {
          urtil.doorCoverageOne = 0.5
          urtil.doorCoverageTwo = 0.5
        } else {
          urtil.doorCoverageOne = null
          urtil.doorCoverageTwo = null
        }
      }

      this.$store.commit('openSnackbar', {
        snackbarText: 'Legacy Quote with Urtil updated with compartments!'
      })
    },
    findWorktopColourName(worktop) {
      if (!this.version?.worktops) return []

      let worktopInfo
      if (!store) return null
      if (store.state.materials) {
        const colouredMaterials = store.state.materials?.filter(material => material.colours)
        const colours = colouredMaterials?.map(colouredMaterial => colouredMaterial.colours)
        for (const materialSet of colours) {
          for (const colourInfo of materialSet) {
            if (colourInfo.value === worktop.colourCode) {
              worktopInfo = colourInfo
            }
          }
        }
        return worktopInfo.text
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
*:not(.v-icon) {
  font-family: 'proxima-nova', sans-serif !important;
  letter-spacing: 0.5px !important;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-variant-ligatures: none;
}

#quote-view {
  width: 100%;

  h1 {
    font-size: 3.125rem;
  }

  .v-expansion-panel {
    &:before {
      box-shadow: none;
    }
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 16px;
  }

  .v-expansion-panel--disabled {
    color: inherit;
  }

  .v-expansion-panel--active:not(:first-child)::after,
  .v-expansion-panel--active + .v-expansion-panel::after {
    opacity: 1;
  }
}
</style>
