<template>
  <div>
    <v-tabs :height="60" background-color="primary" dark slider-color="accent" v-model="activeTab">
      <v-tab :key="0">Scheduled</v-tab>
      <v-tab :key="1">Missing Invoice</v-tab>
      <!-- <v-tab :key="2">US Sales Tax</v-tab> -->
    </v-tabs>
    <component :is="tabComponents[activeTab]"></component>
  </div>
</template>
<script>
import Scheduled from '@/components/orders/Scheduled'
import MissingInvoice from '@/components/orders/MissingInvoice'
// import UsSalesTax from '@/components/orders/UsSalesTax'

export default {
  name: 'orders',
  // components: { Scheduled, MissingInvoice, UsSalesTax },
  components: { Scheduled, MissingInvoice },
  data() {
    return {
      activeTab: 0,
      // tabComponents: ['Scheduled', 'MissingInvoice', 'UsSalesTax']
      tabComponents: ['Scheduled', 'MissingInvoice']
    }
  }
}
</script>
<style lang="scss" scoped></style>
