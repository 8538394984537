<template>
  <div class="pa-5">
    <div v-if="readyForAction">
      <!-- HEADER -->
      <v-container fluid>
        <v-row class="align-baseline mb-8">
          <v-col cols="6">
            <h1>Sprayer List</h1>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <img src="@/assets/final-logo-tight.png" alt="Plykea" width="200px" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="item py-2">
              <div class="caption text--secondary">Customer Name</div>
              <div class="body-1">
                {{ order.customerContactDetails.firstName + ' ' + order.customerContactDetails.lastName }}
              </div>
            </div>
            <div class="item py-2">
              <div class="caption text--secondary">Customer Email</div>
              <div class="body-1">{{ shipment.customerEmail }}</div>
            </div>
          </v-col>
          <v-col cols="6" class="text-right">
            <div class="item py-2">
              <div class="caption text--secondary">Order Number</div>
              <div class="body-1">
                {{ order.orderNumber || order.xeroInvoiceNumber }}
              </div>
            </div>
            <div class="item py-2">
              <div class="caption text--secondary">Dispatch Date</div>
              <div class="body-1">
                {{ $utils.convertDate(shipment.deliveryDate) }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- NOTES -->
      <v-container fluid v-if="version.notes">
        <v-row>
          <v-col cols="12">
            <span class="text-h6 mr-2">Notes</span>
            <v-divider class="mt-2"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>{{ version.notes }}</p>
          </v-col>
        </v-row>
      </v-container>

      <!-- FRONTS -->
      <v-container
        v-for="material in version.materials"
        v-if="
          version.pricing.fronts[material.id] &&
            version.pricing.fronts[material.id].itemCount > 0 &&
            material.grainDirection &&
            material.thickness !== 4 &&
            material.thickness !== 6
        "
        :key="material.id"
        fluid
        class="text-body-2"
      >
        <v-row>
          <v-col cols="12">
            <span class="text-h6 mr-2">{{ material.thickness }}mm {{ material.label }}</span>
            <span class="text-body-2"
              >area: {{ getMaterialSurfaceArea(material.id).area }} <sup>m2</sup> / spray area:
              {{ getMaterialSurfaceArea(material.id).spray }}
              <sup>m2</sup></span
            >
            <v-divider class="my-2"></v-divider>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col cols="1" class="text--disabled">Location</v-col>
          <v-col cols="2" class="text--disabled">Type</v-col>
          <v-col cols="1" class="text--disabled">Dims (mm)</v-col>
          <v-col cols="1" class="text--disabled">Area (sqm)</v-col>
          <v-col cols="1" class="text--disabled">Spray (sqm)</v-col>
          <v-col cols="4" class="text--disabled">Feature</v-col>
          <v-col cols="1" class="text--disabled text-right">Qty</v-col>
          <v-col cols="1" class="text--disabled text-right">Received</v-col>
        </v-row>
        <v-row
          v-for="front in frontsSprayerList"
          v-if="front.material == material.id"
          :key="front.id"
          class="py-0 my-0"
        >
          <v-col cols="1">{{ front.itemNum }}</v-col>
          <v-col cols="2"
            >{{ front.specialLabel ? front.specialLabel : productData.getFrontTypeLabel(front.frontType)
            }}{{
              front.joiningPos && ['top', 'mid', 'bot'].includes(front.joiningPos)
                ? ' (' + getPositionName(front.joiningPos) + ')'
                : ''
            }}</v-col
          >
          <v-col cols="1">{{ front.w }} x {{ front.h }}</v-col>
          <v-col cols="1">{{ (((front.w * front.h) / 1000000) * 2 * front.qty).toFixed(2) }}</v-col>
          <v-col cols="1">{{
            front.specialLabel
              ? ((front.w * front.h) / 1000000).toFixed(2)
              : (((front.w * front.h) / 1000000) * 2 * front.qty).toFixed(2)
          }}</v-col>
          <v-col cols="4">{{ front.handle ? getHandleName(front.handle) + ' Handle' : 'N/A' }}</v-col>
          <v-col cols="1" class="value text-right">{{ front.qty }}</v-col>
          <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
        </v-row>

        <v-row
          v-for="coverPanel in filteredCoverPanels"
          v-if="coverPanel.material == material.id"
          :key="coverPanel.id"
          class="py-0 my-0"
        >
          <v-col cols="1">{{ coverPanel.itemNum }}</v-col>
          <v-col cols="2">{{ productData.getCoverPanelTypeLabel(coverPanel.coverPanelType) }}</v-col>
          <v-col cols="1">{{ coverPanel.w }} x {{ coverPanel.h }}</v-col>
          <v-col cols="1">{{ (((coverPanel.w * coverPanel.h) / 1000000) * 2 * coverPanel.qty).toFixed(2) }}</v-col>
          <v-col cols="1">{{ calcCoverPanelSprayArea(coverPanel).toFixed(2) }}</v-col>
          <v-col cols="4">{{
            coverPanel.lighting
              ? `${coverPanel.lighting}mm Lighting ${
                  coverPanel.lightingRef === 'r'
                    ? `from the Right ${coverPanel.lightingOffset}mm`
                    : coverPanel.lightingRef === 'l'
                    ? `from the Left ${coverPanel.lightingOffset}mm`
                    : 'Centered'
                } `
              : coverPanel.coverPanelType === 'v-plinth' && coverPanel.vent
              ? `${coverPanel.vent}mm Vent from the ${coverPanel.ventRef === 'r' ? 'Right' : 'Left'} ${
                  coverPanel.ventOffset
                }mm`
              : 'n/a'
          }}</v-col>
          <v-col cols="1" class="value text-right">{{ coverPanel.qty }}</v-col>
          <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
        </v-row>
      </v-container>

      <!-- URTILS -->
      <v-container fluid v-if="urtilsWithVeneer && urtilsWithVeneer.length > 0" class="text-body-2">
        <v-row>
          <v-col cols="12">
            <span class="text-h6 mr-2">Ürtils</span>
          </v-col>
        </v-row>
        <div v-for="urtil in urtilsWithVeneer" :key="urtil.id" class="pb-2">
          <v-divider class="my-2"></v-divider>
          <v-row>
            <v-col cols="6" class="text-h8 text-left">
              {{ getUrtilType(urtil.type) }} {{ getMaterialName(urtil.carcass) + ' Ürtil with' }}
              {{ urtil.compartments + ' Compartments & ' }}
              {{
                urtil.back && getMaterial(urtil.back).hasGrain
                  ? getMaterialName(urtil.back) + ' Back'
                  : 'Non-veneer Back'
              }}
              {{
                urtil.shelving
                  ? 'and ' + urtil.shelving[0].toUpperCase() + urtil.shelving.slice(1).toLowerCase() + ' Shelves'
                  : ''
              }}
              {{ '[W' + urtil.w + 'x' + 'H' + urtil.h + 'x' + 'D' + urtil.d + ']' }}</v-col
            >
            <v-col cols="6" class="text-h8 text-right"
              >Name: "{{ urtil.name.toUpperCase() }}" / Total spray area: {{ getUrtilSurfaceArea(urtil) }}
              <sup>m2</sup> / Parts quantity: {{ getNumUrtilParts(urtil) }}</v-col
            >
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row class="pl-2">
            <v-col cols="10" class="text--disabled">Part Type</v-col>
            <v-col cols="1" class="text--disabled text-right">Qty</v-col>
            <v-col cols="1" class="text--disabled text-right">Received</v-col>
          </v-row>
          <v-row class="pl-2">
            <v-col cols="10">Top & Base Ürtil Carcass</v-col>
            <v-col cols="1" class="value d-flex justify-end">2</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row class="pl-2">
            <v-col cols="10">Left & Right Ürtil Carcass Sides</v-col>
            <v-col cols="1" class="value d-flex justify-end">2</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <!-- // NEED TO ADJUST FOR COMPARTMENT -->
          <v-row v-if="urtil.compartments > 1" class="pl-2">
            <v-col cols="10">
              {{ urtil.compartments > 1 ? urtil.compartments - 1 + ' Ürtil Vertical Dividers' : '' }}</v-col
            >
            <v-col cols="1" class="value d-flex justify-end">{{ urtil.compartments - 1 }}</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row v-if="urtil.shelving" class="pl-2">
            <v-col cols="10">{{
              getNumShelves(urtil) +
                ' x ' +
                urtil.shelving[0].toUpperCase() +
                urtil.shelving.slice(1).toLowerCase() +
                ' Shelves'
            }}</v-col>
            <v-col cols="1" class="value d-flex justify-end">{{ getNumShelves(urtil) }}</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row v-if="urtil.back && getMaterial(urtil.back).hasGrain" class="pl-2">
            <v-col cols="10">{{ getMaterialName(urtil.back) }} Ürtil Back</v-col>
            <v-col cols="1" class="value d-flex justify-end">1</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row v-if="urtil.type !== 'o' && urtil.material1 && getMaterial(urtil.material1).hasGrain" class="pl-2">
            <v-col cols="10"
              >{{ getMaterialName(urtil.material1) }} Ürtil Door 1 (covering {{ urtil.doorCoverageOne }}
              {{ urtil.doorCoverageOne > 1 ? 'compartments' : 'compartment' }})</v-col
            >
            <v-col cols="1" class="value d-flex justify-end">1</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row v-if="urtil.type === 't' && urtil.material2 && getMaterial(urtil.material2).hasGrain" class="pl-2">
            <v-col cols="10"
              >{{ getMaterialName(urtil.material2) }} Ürtil Door 2 (covering {{ urtil.doorCoverageTwo }}
              {{ urtil.doorCoverageTwo > 1 ? 'compartments' : 'compartment' }})</v-col
            >
            <v-col cols="1" class="value d-flex justify-end">1</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
        </div>
      </v-container>
      <!-- PANTRIES -->
      <v-container fluid v-if="pantriesWithVeneer && pantriesWithVeneer.length > 0" class="text-body-2">
        <v-row>
          <v-col cols="12">
            <span class="text-h6 mr-2">{{ version.pantries.length === 1 ? 'Pantry' : 'Pantries' }}</span>
          </v-col>
        </v-row>
        <div v-for="pantry in pantriesWithVeneer" :key="pantry.id" class="pb-2">
          <v-divider class="my-2"></v-divider>
          <v-row>
            <v-col cols="6" class="text-h8 text-left"
              >{{
                !pantry.base
                  ? getMaterialName(pantry.carcass).split('/')[0] + ' Pantry with Matching Base'
                  : pantry.base && !getMaterial(pantry.base).hasGrain
                  ? getMaterialName(pantry.carcass).split('/')[0] +
                    ' Pantry with ' +
                    getMaterialName(pantry.base).split('/')[0] +
                    ' Base'
                  : getMaterialName(pantry.carcass).split('/')[0] + ' Pantry with Matching Base'
              }}
              {{
                pantry.back && getMaterial(pantry.back).hasGrain
                  ? ' and ' + getMaterialName(pantry.back) + ' Back'
                  : ' and Non-veneer Back'
              }}
              {{
                pantry.pantryHinge === 'o'
                  ? '- [W' + pantry.w + 'x' + 'H' + pantry.h + 'x' + 'D' + (pantry.d + 14) + ']'
                  : '- [W' + pantry.w + 'x' + 'H' + pantry.h + 'x' + 'D' + pantry.d + ']'
              }}</v-col
            >
            <v-col cols="6" class="text-h8 text-right"
              >Name: "{{ pantry.name.toUpperCase() }}" / Total spray area: {{ getPantrySurfaceArea(pantry) }}
              <sup>m2</sup> / Parts quantity: {{ getNumPantryParts(pantry) }}</v-col
            >
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row class="pl-2">
            <v-col cols="10" class="text--disabled">Part Type</v-col>
            <v-col cols="1" class="text--disabled text-right">Qty</v-col>
            <v-col cols="1" class="text--disabled text-right">Received</v-col>
          </v-row>
          <v-row v-if="pantry.carcass && getMaterial(pantry.carcass).hasGrain" class="pl-2">
            <v-col cols="10">{{ pantry.base ? 'Top Pantry Carcass' : 'Top & Base Pantry Carcass' }}</v-col>
            <v-col cols="1" class="value d-flex justify-end">{{ pantry.base ? 1 : 2 }}</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row v-if="pantry.carcass && getMaterial(pantry.carcass).hasGrain" class="pl-2">
            <v-col cols="10">Left & Right Pantry Carcass Sides</v-col>
            <v-col cols="1" class="value d-flex justify-end">2</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row v-if="pantry.carcass && getMaterial(pantry.carcass).hasGrain" class="pl-2">
            <v-col cols="10">{{
              `Adjustable Shelf with D${productData.getPantryShelfDepth(
                pantry.d,
                'adj'
              )}mm x W${productData.getPantryShelfWidth(pantry.w, 'adj')}mm`
            }}</v-col>
            <v-col cols="1" class="value d-flex justify-end">2</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row v-if="pantry.carcass && getMaterial(pantry.carcass).hasGrain" class="pl-2">
            <v-col cols="10">{{
              `Bottom Fixed Shelf with D${productData.getPantryShelfDepth(
                pantry.d,
                'fixed'
              )}mm x W${productData.getPantryShelfWidth(pantry.w, 'fixed')}mm`
            }}</v-col>
            <v-col cols="1" class="value d-flex justify-end">1</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
          <v-row v-if="pantry.back && getMaterial(pantry.back).hasGrain" class="pl-2">
            <v-col cols="10">{{ getMaterialName(pantry.back) }} Pantry Back</v-col>
            <v-col cols="1" class="value d-flex justify-end">1</v-col>
            <v-col cols="1" class="value d-flex justify-end"><div class="box"></div></v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <v-overlay :value="!readyForAction">
      <v-progress-circular v-if="!error" :size="50" color="primary" indeterminate></v-progress-circular>
      <div v-else>{{ error }}</div>
    </v-overlay>
  </div>
</template>

<script>
import productData from '@/js/productData'

export default {
  name: 'SprayerView',
  props: ['shipmentID'],
  data() {
    return {
      productData: productData,
      shipment: null,
      version: null,
      order: null,
      readyForAction: false,
      error: null,
      lacquerLineEdgeInset: 130,
      minLacquerSize: 500,
      // NEED TO ADJUST FOR COMPARTMENT
      centralDivideThreshold: 1000
    }
  },
  async created() {
    const shipmentSnap = await this.$db
      .collection('shipments')
      .doc(this.shipmentID)
      .get()
    this.shipment = shipmentSnap.data()

    const quoteSnap = await this.$db
      .collection('quotes')
      .doc(this.shipment.quoteID)
      .get()
    this.version = this.$utils.getVersionByID(quoteSnap.data().versions, this.shipment.versionID)

    if (!this.version.pricing) {
      this.error =
        'Quote is currently unpublished. Check with ' + this.version.creatorEmail + ' if it can be republished.'
      return
    }

    const orderSnap = await this.$db
      .collection('orders')
      .doc(this.shipment.orderID)
      .get()
    this.order = orderSnap.data()

    this.readyForAction = true
  },
  computed: {
    frontsSprayerList() {
      const frontsSprayerListArray = []
      this.version.fronts.forEach(front => {
        if (front.frontType.includes('-') && front.frontType.split('-')[2] == 'cnr') {
          // create special front Obj for door, narrowFiller & wideFiller
          let cabinet = front.frontType.split('-')[1]

          let cnrFrontArray = [
            {
              frontType: front.frontType,
              w: front.w,
              h: front.h,
              handle: front.handle,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid
            },
            {
              frontType: front.frontType,
              w: cabinet == 'w' ? 100 : 60,
              h: front.h,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid + '-nf',
              specialLabel: 'Narrow corner filler'
            },
            {
              frontType: front.frontType,
              w: cabinet == 'w' ? 120 : 80,
              h: front.h,
              itemNum: front.itemNum,
              material: front.material,
              qty: 1,
              id: front.uid + '-wf',
              specialLabel: 'Wide corner filler'
            }
          ]
          cnrFrontArray.forEach(cnrUnit => frontsSprayerListArray.push(cnrUnit))
        } else {
          let frontObj = {
            frontType: front.frontType,
            w: front.w,
            h: front.h,
            handle: front.handle,
            itemNum: front.itemNum,
            material: front.material,
            qty: front.qty,
            id: front.uid,
            joiningPos: front?.joiningPos,
            joiningDouble: front?.joiningDouble
          }
          frontsSprayerListArray.push(frontObj)
        }
      })
      return frontsSprayerListArray
    },
    urtilsWithVeneer() {
      const a = []
      if (!this.version.urtils) return
      for (const urtil of this.version.urtils) {
        let hasVeneer = false
        if (urtil.back && this.getMaterial(urtil.back)?.hasGrain) hasVeneer = true
        if (urtil.carcass && this.getMaterial(urtil.carcass)?.hasGrain) hasVeneer = true
        if (urtil.material1 && this.getMaterial(urtil.material1)?.hasGrain) hasVeneer = true
        if (urtil.material2 && this.getMaterial(urtil.material2)?.hasGrain) hasVeneer = true
        if (hasVeneer) a.push(urtil)
      }
      return a
    },
    pantriesWithVeneer() {
      const a = []
      if (!this.version.pantries) return
      for (const pantry of this.version.pantries) {
        let hasVeneer = false
        if (pantry.back && this.getMaterial(pantry.back).hasGrain) hasVeneer = true
        if (pantry.carcass && this.getMaterial(pantry.carcass).hasGrain) hasVeneer = true
        if (hasVeneer) a.push(pantry)
      }
      return a
    },
    filteredCoverPanels() {
      return this.version.coverPanels.filter(o => {
        return !(o.coverPanelType === 'plinth' && o.h === 75)
      })
    }
  },
  methods: {
    getNumShelves(urtil) {
      if (!urtil.shelving) return 0
      return urtil.shelving[0] !== 'fixed' ? urtil.shelvingOptions.qty * urtil.compartments : urtil.shelvingOptions.qty
    },
    getHandleName(id) {
      for (var i = 0; i < this.version.handles.length; i++) {
        if (this.version.handles[i].id == id) {
          return this.version.handles[i].label
        }
      }
    },
    getPositionName(pos) {
      let positionName
      switch (pos) {
        case 'top':
          positionName = 'Joined Top'
          break
        case 'mid':
          positionName = 'Joined Middle'
          break
        case 'bot':
          positionName = 'Joined Bottom'
          break
      }
      return positionName
    },
    getMaterialName(code) {
      for (var i = 0; i < this.version.materials.length; i++) {
        if (code == this.version.materials[i].id) {
          var m = this.version.materials[i]
          if (['Reeded Glass Frosted Frosted', 'Reeded Glass Clear Clear'].includes(m.label)) {
            let lastIndex = m.label.lastIndexOf(' ')
            return m.label.substring(0, lastIndex)
          }
          return m.colourCode ? m.label + ' / ' + m.colourCode : m.label
        }
      }
    },
    getMaterial(code) {
      const mat = this.version.materials.find(material => {
        return code === material.id
      })
      return mat
    },
    getUrtilType(type) {
      if (type == 'o') {
        return 'Open'
      } else if (type == 's') {
        return 'Single-Door'
      } else if (type == 't') {
        return 'Twin-Door'
      }
    },
    getNumUrtilParts(urtil) {
      let numParts = 0

      // carcass
      numParts += 4

      // dividers
      numParts += urtil.compartments - 1

      // back
      if (urtil.back && this.getMaterial(urtil.back).hasGrain) numParts++

      // urtil doors
      urtil.type == 's' && urtil.material1 && this.getMaterial(urtil.material1).hasGrain
        ? numParts++
        : urtil.type == 't' && urtil.material2 && this.getMaterial(urtil.material2).hasGrain
        ? (numParts += 2)
        : (numParts += 0)

      // shelves
      if (urtil.shelvingOptions) {
        const numShelves = this.getNumShelves(urtil)
        numParts += numShelves
      }

      return numParts
    },
    getNumPantryParts(pantry) {
      let numParts = 0

      // if the pantry carcass is veneer, it will always start with 6 parts
      if (this.getMaterial(pantry.carcass).hasGrain) {
        numParts = 4 + 3
        if (pantry.base) {
          // if base is laminate then subtract 1
          numParts -= 1
        }
      } else {
        // if the pantry carcass is laminate, it will always start with 0 parts
        numParts = 0
      }

      if (this.getMaterial(pantry.back).hasGrain) {
        // if the back is then add 1
        numParts += 1
      }
      // if base nor back is veneer then its still the starting 6 parts

      return numParts
    },
    getUrtilSurfaceArea(urtil) {
      let area = 0

      // urtil carcass
      if (!this.getMaterial(urtil.carcass).colourCode) {
        area += (urtil.w - 26) * urtil.d * 4 + urtil.h * urtil.d * 4

        // divider
        if (urtil.w > 1000 || urtil.compartments > 1) area += (urtil.h - 10) * (urtil.d - 39) * 2
      }

      // urtil back
      if (urtil.back && !this.getMaterial(urtil.back).colourCode) area += (urtil.w - 11) * (urtil.h - 11)

      // urtil doors
      if ((urtil.type == 's' || urtil.type == 't') && urtil.material1 && !this.getMaterial(urtil.material1).colourCode)
        area += urtil.w * (urtil.h - 24)
      if (urtil.type == 't' && urtil.material2 && !this.getMaterial(urtil.material2).colourCode)
        area += urtil.w * (urtil.h - 24)

      // urtil shelves
      if (urtil.shelving && urtil.shelvingOptions) {
        area += urtil.w * (urtil.d - 60) * urtil.shelvingOptions.qty * 2
      }

      return area / 1000000
    },
    getPantrySurfaceArea(pantry) {
      let area = 0
      let pantryDepth

      if (this.getMaterial(pantry.carcass).hasGrain) {
        pantry.pantryHinge === 'o' ? (pantryDepth = pantry.d + 14) : (pantryDepth = pantry.d)
        // pantry carcass is only sprayed on the inside
        area += (pantry.w - 26) * pantryDepth * 1 + pantry.h * pantryDepth * 2
        // pantry shelves sprayed top & bottom
        area += (pantry.w - 36) * productData.getPantryShelfDepth(pantry.d, 'adj') * 2 * 2
        area += (pantry.w - 36) * productData.getPantryShelfDepth(pantry.d, 'fixed') * 1 * 2
        // add another base area if matching base
        if (!pantry.base) {
          area += (pantry.w - 26) * pantryDepth * 1
        }
      }

      // add back area if veneer
      if (this.getMaterial(pantry.back).hasGrain) {
        area += pantry.w * (pantry.h - 24)
      }

      return area / 1000000
    },

    getMaterialSurfaceArea(materialID) {
      let total = 0
      let spray = 0
      const narrowFillerWidth = 60
      const wideFillerWidth = 80

      let i
      for (i = 0; i < this.version.fronts.length; i++) {
        let front = this.version.fronts[i]
        let specialType = front.frontType.split('-')[2]
        if (front.material == materialID) {
          if (specialType == 'cnr') {
            total += (front.w * front.h * 2 + narrowFillerWidth * front.h * 2 + wideFillerWidth * front.h * 2) / 1000000
            spray += (front.w * front.h * 2 + narrowFillerWidth * front.h + wideFillerWidth * front.h) / 1000000
          } else {
            total += ((front.w * front.h * front.qty) / 1000000) * 2
            spray += ((front.w * front.h * front.qty) / 1000000) * 2
          }
        }
      }

      for (const cp of this.filteredCoverPanels) {
        if (cp.material == materialID) {
          total += ((cp.w * cp.h * cp.qty) / 1000000) * 2
          spray += this.calcCoverPanelSprayArea(cp)
        }
      }

      return {
        area: total.toFixed(2),
        spray: spray.toFixed(2)
      }
    },
    calcCoverPanelSprayArea(cp) {
      const fullSide = (cp.w * cp.h) / 1000000
      const edgeSide = ((this.lacquerLineEdgeInset * cp.h) / 1000000) * 2
      const edgeSideHorizontal = ((this.lacquerLineEdgeInset * cp.w) / 1000000) * 2

      let area

      switch (cp.coverPanelType) {
        case 'filler':
        case 'back':
          area = fullSide
          break
        case 'underside':
        case 'top':
          area = !cp.doubleSided && cp.h >= this.minLacquerSize ? fullSide + edgeSideHorizontal : fullSide * 2
          break
        case 'shelf':
        case 'plinth':
          area = fullSide * 2
          break
        default:
          area = !cp.doubleSided && cp.w >= this.minLacquerSize ? fullSide + edgeSide : fullSide * 2
      }

      return area * cp.qty
    }
  }
}
</script>
<style lang="scss" scoped>
h1 {
  font-size: 3.125rem;
}
</style>
