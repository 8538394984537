<template>
  <div>
    <div id="production-table">
      <div class="table-header">
        <v-chip
          v-if="['sho@plykea.com', 'kath@plykea.com', 'adam@plykea.com'].includes($store.state.user.email)"
          class="text-body-2 mr-4"
          color="green"
          >{{ ukOrderTally }}</v-chip
        >
        <v-text-field
          v-model="tableSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :clearable="true"
        ></v-text-field>
      </div>
      <div id="dispatchedTable">
        <v-data-table
          :items="shipments"
          :headers="headers"
          :search="tableSearch"
          :loading="loading"
          :custom-sort="customSort"
          :no-data-text="noDataText"
          sortBy="dateScheduled"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.orderNumber || props.item.invoiceNumber }}</td>
              <td>{{ props.item.customerEmail }}</td>
              <td>{{ props.item.creatorEmail }}</td>
              <td>{{ formatLineItems(props.item.lineItems) }}</td>
              <td>{{ $utils.humanFromVuetify(props.item.dateScheduled) }}</td>
              <td style="display: flex; justify-content: flex-end">
                <v-tooltip top :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-icon small class="mr-2" @click="viewQuote(props.item)" v-on="on">mdi-clipboard-text</v-icon>
                  </template>
                  <span>Original Quote</span>
                </v-tooltip>
                <v-tooltip top :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-icon small class="mr-2" @click="packingView(props.item)" v-on="on">mdi-clipboard-check</v-icon>
                  </template>
                  <span>Packing List</span>
                </v-tooltip>
                <v-tooltip top :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      :disabled="cloning"
                      :size="15"
                      style="margin-top: -1px"
                      @click="
                        () => {
                          selectedShipment = props.item
                          cloneDialog = true
                        }
                      "
                      v-on="on"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Clone to New Quote</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- Component Overlay Dialog -->
    <v-dialog v-model="dialogVisible" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary" class="no-print elevation-0">
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="
              () => {
                dialogVisible = false
                overlayComponent = null
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <component
          :is="overlayComponent"
          :shipment="selectedShipment"
          :quoteID="selectedShipment.quoteID"
          :versionID="selectedShipment.versionID"
        ></component>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Clone Dialogue -->
    <v-dialog v-model="cloneDialog" width="500">
      <v-card>
        <v-card-title class="error white--text title">
          Clone Quote
        </v-card-title>
        <v-card-text> Are you sure you want to clone this quote? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="cloneDialog = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="cloneToQuote">Clone</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QuoteEdit from '@/views/QuoteEdit'
import uid from 'uid'

export default {
  name: 'Dispatched',
  components: { QuoteEdit },
  data() {
    return {
      tallyOrders: [],
      shipments: [],
      shipmentDetailsVisible: false,
      selectedShipment: {},
      loading: true,
      cloning: false,
      cloneDialog: false,
      headers: [
        {
          text: 'Order / Inv #',
          sortable: false,
          value: 'orderNumber'
        },
        {
          text: 'Customer',
          sortable: false,
          value: 'customerEmail'
        },
        {
          text: 'Creator',
          sortable: false,
          value: 'creatorEmail'
        },
        {
          text: 'Items',
          sortable: false,
          value: 'lineItems'
        },
        {
          text: 'Delivery Date',
          sortable: true,
          value: 'dateScheduled'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          width: 160,
          value: null
        }
      ],
      tableSearch: '',
      sortConfig: {
        sortBy: 'dateScheduled',
        rowsPerPage: 10,
        descending: true
      },
      overlayComponent: null,
      dialogTitle: null,
      dialogVisible: false
    }
  },
  computed: {
    noDataText() {
      return this.loading ? '' : 'Nothing to display'
    },
    ukOrderTally() {
      let totalSum = 0
      for (const order of this.tallyOrders) {
        totalSum += order?.total
      }
      return 'Orders Totals = £' + this.$numeral(totalSum).format('0,0.00')
    }
  },
  methods: {
    formatLineItems(a) {
      var s = ''
      for (var i = 0; i < a.length; i++) {
        s += a[i]
        if (i < a.length - 1) s += ', '
      }
      return s
    },
    packingView(shipment) {
      var routeData = this.$router.resolve({
        name: 'packingView',
        params: { shipmentID: shipment.shipmentID }
      })
      window.open(routeData.href, '_blank')
    },
    customSort(items, index, isDescending) {
      // TODO materialOrdered sorting seems buggy?
      items.sort((a, b) => {
        var aDate = this.$utils.millisFromVuetify(a[index])
        var bDate = this.$utils.millisFromVuetify(b[index])
        if (isDescending) {
          return aDate < bDate ? 1 : -1
        } else {
          return aDate < bDate ? -1 : 1
        }
      })
      return items
    },
    viewQuote(shipment) {
      this.dialogVisible = true
      this.selectedShipment = shipment
      this.dialogTitle = shipment.customerEmail
      this.overlayComponent = 'QuoteEdit'
    },
    cloneToQuote(shipment) {
      this.cloning = true
      this.$db
        .collection('quotes')
        .doc(shipment.quoteID)
        .get()
        .then(quoteRef => {
          var quote = quoteRef.data()

          quote.creatorEmail = this.$firebase.auth().currentUser.email
          quote.deadReason = null
          quote.orders = {}
          quote.dateCreated = this.$firebase.firestore.Timestamp.now()

          for (var i = 0; i < quote.versions.length; i++) {
            if (quote.versions[i].id == shipment.versionID) {
              var version = quote.versions[i]

              version.id = uid(10)
              version.published = false
              version.date = this.$firebase.firestore.Timestamp.now()

              quote.versions = [version]
            }
          }

          this.$db
            .collection('quotes')
            .add(quote)
            .then(newQuoteRef => {
              this.$router.push({
                name: 'quoteEdit',
                params: { quoteID: newQuoteRef.id }
              })
            })
        })
    }
  },
  async created() {
    await this.$bind(
      'tallyOrders',
      this.$db
        .collection('orders')
        .where('foc', '==', false)
        .where('dispatched', '==', true)
      // .where('numPayments', '>', 0)
    )
    // TODO if this bugs out might need to use $utils ensureFirestoreTimestamp on the date values
    await this.$db
      .collection('shipments')
      .where('shipped', '==', true)
      .onSnapshot(shipmentsQuery => {
        this.loading = false
        var a = []
        shipmentsQuery.forEach(shipmentRef => {
          var shipment = shipmentRef.data()
          shipment.shipmentID = shipmentRef.id
          shipment.dateScheduled = shipment.dateScheduled == null ? null : shipment.dateScheduled.toDate()
          shipment.cadFileSent = shipment.cadFileSent == null ? null : shipment.cadFileSent.toDate()
          shipment.materialOrdered = shipment.materialOrdered == null ? null : shipment.materialOrdered.toDate()
          shipment.completionDate = shipment.completionDate == null ? null : shipment.completionDate.toDate()
          shipment.sprayerDate = shipment.sprayerDate == null ? null : shipment.sprayerDate.toDate()
          shipment.startDate = shipment.startDate == null ? null : shipment.startDate.toDate()
          a.push(shipment)
        })
        this.shipments = a
      })
  }
}
</script>
