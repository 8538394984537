var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class DrawerHolesUs {
  constructor(w, h, front) {
    this.models = {}
    this.width = w
    this.height = h
    this.front = front

    this.drillDiameter = 5
    this.posArray = [39, 58, 117, 136, 185, 204]
    this.posArray378 = [39, 58, 117, 136, 185, 204, 539, 558, 617, 636]

    // US Internal Drawer Holes Settings
    this.offsetX = h === 100 || h === 165.1 || h === 212.725 ? 11.913 : 31.75
    this.posArrayInternalLow = [48.158, 67.158]
    this.posArrayInternalMid = [48.158, 67.158, 126.03, 145.03]
    this.posArrayInternalHigh = [38.235, 57.235, 114.334, 183.219, 202.219]

    this.holeCount
    if (this.front.special === 'int') {
      if (this.height <= 100) {
        this.holeCount = 2
      } else if (this.height <= 165.1) {
        this.holeCount = 4
      } else if (this.height <= 212.725) {
        this.holeCount = 5
      } else {
        this.holeCount = 0 // wrong int-drw height so just remove holes
      }
    } else {
      if (this.height <= 127) {
        // 5 inches
        if (this.front.handle.value === 'j') {
          this.posArray.slice(2)
        }
        this.holeCount = 2
      } else if (this.height <= 254) {
        // 10 inches
        if (this.front.handle.value === 'j') {
          this.posArray.slice(1)
        }
        this.holeCount = 4
      } else {
        this.holeCount = 6
      }
    }

    this.generateProductionPaths()
  }

  generateProductionPaths() {
    if (this.getHoles()) {
      this.models.leftHoles = this.getHoles()
      this.models.leftHoles.layer = layerNames.drawerHoleMedium.label
      this.models.rightHoles = this.getHoles()
      this.models.rightHoles.layer = layerNames.drawerHoleMedium.label

      makerjs.model.move(this.models.leftHoles, [this.offsetX, 0])
      makerjs.model.move(this.models.rightHoles, [this.width - this.offsetX, 0])
    }
  }

  getHoles() {
    // catch the 15inch (=381mm) pull out cupboard
    let src, count
    if (this.width == 378 && this.height == 759) {
      src = this.posArray378
      count = this.posArray378.length
    } else if (this.front.special === 'int') {
      // catch int-drw
      switch (this.height) {
        case 100:
          src = this.posArrayInternalLow
          count = this.holeCount
          break
        case 165.1:
          src = this.posArrayInternalMid
          count = this.holeCount
          break
        case 212.725:
          src = this.posArrayInternalHigh
          count = this.holeCount
          break
        default:
          count = this.holeCount
          console.log('no holes', this.height)
          break
      }
    } else if (this.height < 100 && this.front.handle.value === 'j') {
      src = [this.posArray[0]]
      count = 1
    } else {
      src = this.posArray
      count = this.holeCount
    }

    let points = []
    if (count > 0) {
      for (let i = 0; i < count; i++) {
        points.push([0, src[i]])
      }
      return new makerjs.models.Holes(this.drillDiameter / 2, points)
    } else {
      // blank
      return null
    }
  }
}
