const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class JHandle {
  constructor(width, height, region) {
    this.models = {}

    this.region = region

    this.handleWidth = parseFloat(width)
    this.handleHeight = parseFloat(height)

    this.handleCornerRadius = 2
    this.screwHoleHeight = 9
    this.screwHoleRadius = 1.5

    // calculate spacing and hole count
    this.screwHoleCount = null
    this.screwHoleXoffset = null
    this.screwHoleSpacing = null

    for (let i = 50; i < 92; i++) {
      this.screwHoleSpacing = i
      // change j for gap from edge
      for (let j = 8; j < 15; j++) {
        this.screwHoleXoffset = j
        let roundedHandleWidth = Math.round(this.handleWidth)
        this.screwHoleCount = (roundedHandleWidth - 2 * this.screwHoleXoffset) / this.screwHoleSpacing
        if (this.screwHoleCount % 1 == 0) {
          break
        }
      }
      if (this.screwHoleCount % 1 == 0) {
        break
      }
    }

    this.generateJHandle()
  }

  generateJHandle() {
    // check if j-handle
    this.models.outline = {
      layer: layerNames.insertCutout.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 0],
          end: [this.handleWidth, 0]
        },
        line2: {
          type: 'line',
          origin: [this.handleWidth, 0],
          end: [this.handleWidth, this.handleHeight]
        },
        line3: {
          type: 'line',
          origin: [this.handleWidth, this.handleHeight],
          end: [0, this.handleHeight]
        },
        line4: {
          type: 'line',
          origin: [0, this.handleHeight],
          end: [0, 0]
        }
      }
    }

    if (this.handleHeight == 52.5) {
      this.models.outline.paths.arc1 = makerjs.path.fillet(
        this.models.outline.paths.line3,
        this.models.outline.paths.line2,
        this.handleCornerRadius
      )
      this.models.outline.paths.arc2 = makerjs.path.fillet(
        this.models.outline.paths.line3,
        this.models.outline.paths.line4,
        this.handleCornerRadius
      )
    }
    // array for all screw hole position
    const screwHolesPos = []

    let correctXoffset
    if (Math.round(this.handleWidth) > this.handleWidth) {
      correctXoffset = (Math.round(this.handleWidth) - this.handleWidth).toFixed(2) / 2 + this.screwHoleXoffset
    } else {
      correctXoffset = (this.handleWidth - Math.round(this.handleWidth)).toFixed(2) / 2 + this.screwHoleXoffset
    }
    let xPos = 0

    for (let i = 0; i < this.screwHoleCount + 1; i++) {
      screwHolesPos.push([xPos + correctXoffset, this.screwHoleHeight])
      xPos += this.screwHoleSpacing
    }

    // add screw holes
    this.models.screwHoles = new makerjs.models.Holes(this.screwHoleRadius, screwHolesPos)
    this.models.screwHoles.layer = layerNames.insertCountersink.label

    if (this.handleHeight > 52.5) {
      this.models.bracketHoles = makerjs.model.mirror(this.models.screwHoles, false, true)
      makerjs.model.moveRelative(this.models.bracketHoles, [0, this.handleHeight])
    }

    if (this.handleHeight == 108) {
      this.models.shadowLine = {
        layer: layerNames.shadowLine.label,
        paths: {
          line1: {
            type: 'line',
            origin: [-2, this.handleHeight / 2],
            end: [this.handleWidth + 2, this.handleHeight / 2]
          }
        }
      }
    }
  }
}
