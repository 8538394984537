let makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

export default class CoverPanel {
  constructor(panel, font, region) {
    this.panel = panel
    this.font = font
    this.panelCornerRadius = 2
    this.trimAmount = 3
    this.models = {}
    this.labelInset = 15
    this.lacquerLineEdgeInset = 130
    this.lacquerLineSideInset = 100
    this.minWidthForLacquer = 500
    this.lacquerCrossSize = 25

    // lighting data
    this.lightingPadding = 1
    this.lightingDepth = 21
    this.lightingHeight = 15
    this.lightingRebateCornerPoke = 1.5
    this.lightCableCoverWidth = 10
    this.lightCableWidth = 5
    this.lightCableCoverThickness = 6

    // v-plinth data
    this.vPlinthGrooveHeight = 8
    this.numOfVPlinthGrooves = 3
    this.vPlinthGrooveSpacing = 20

    this.height = panel.type == 'filler' ? panel.h - this.trimAmount : panel.h
    this.width = panel.w

    this.models.panel = new makerjs.models.RoundRectangle(panel.w, this.height, this.panelCornerRadius)
    this.models.panel.layer = layerNames.cutout.label

    let label
    switch (panel.type) {
      case 'end-b':
      case 'end-w':
        label = 'CP' + panel.itemNum
        break
      // case 'v-plinth':
      //   label = 'VENT'
      //   break
      case 'plinth':
        label = panel.w == 2400 || panel.w == 3000 ? null : 'PLINTH'
        break
      case 'filler':
        label = 'FP' + panel.itemNum
        break
      case 'back':
        label = 'BK' + panel.itemNum
        break
      case 'top':
        label = 'TP' + panel.itemNum
        break
      case 'underside':
        label = 'UP' + panel.itemNum
        break
      case 'custom':
        label = 'C' + panel.itemNum
        break
      case 'shelf':
        label = null
        break
      default:
        console.log('No label for cover panel type', panel.type)
        label = panel.itemNum.toString()
    }

    if (label) {
      let text = new makerjs.models.Text(font, label, 8)
      this.models.snazzyText = makerjs.model.mirror(text, true, false)
      this.models.snazzyText.layer = panel.doubleSided ? layerNames.notes.label : layerNames.engrave.label

      let textMeasurements = makerjs.measure.modelExtents(this.models.snazzyText)
      makerjs.model.moveRelative(this.models.snazzyText, [-textMeasurements.center[0], -textMeasurements.center[1]])
      if (panel.type == 'underside') {
        // put engraving towards cable and 50mm off edge
        this.models.snazzyText = makerjs.model.rotate(this.models.snazzyText, 180)
        makerjs.model.moveRelative(this.models.snazzyText, [0, this.panel.h - 70])
      }

      !panel.vent
        ? makerjs.model.moveRelative(this.models.snazzyText, [panel.w / 2, 20])
        : panel.ventRef === 'r'
        ? makerjs.model.moveRelative(this.models.snazzyText, [(panel.w - panel.vent - panel.ventOffset) / 2, 20])
        : makerjs.model.moveRelative(this.models.snazzyText, [
            panel.w - (panel.w - panel.vent - panel.ventOffset) / 2,
            20
          ])
    }

    if (region !== 'us' && panel.doubleSided !== true && panel.material.hasGrain) {
      switch (panel.type) {
        case 'end-b':
        case 'end-w':
          if (this.width >= this.minWidthForLacquer) this.addVerticalLacquerGuides()
          break
        case 'top':
        case 'underside':
          if (this.height >= this.minWidthForLacquer) this.addHorizontalLacquerGuides()
          break
        case 'filler':
        case 'back':
          this.addNoLacquerMark()
          break
        default:
      }
    }

    if (panel.lighting) {
      this.createLighting()
    }

    if (panel.vent) {
      this.createVent()

      // // align label
      // let align = new makerjs.models.Text(font, 'ALIGN', 8)
      // this.models.align = makerjs.model.mirror(align, true, false)
      // this.models.align = makerjs.model.rotate(this.models.align, -90)
      // this.models.align.layer = layerNames.engrave.label

      // let alignMeasurements = makerjs.measure.modelExtents(this.models.align)
      // makerjs.model.moveRelative(this.models.align, [-alignMeasurements.center[0], -alignMeasurements.center[1]])
      // panel.ventRef === 'r'
      //   ? makerjs.model.moveRelative(this.models.align, [20, panel.h / 2])
      //   : makerjs.model.moveRelative(this.models.align, [panel.w - 20, panel.h / 2])

      // // trim label
      // let trim = new makerjs.models.Text(font, 'TRIM', 8)
      // this.models.trim = makerjs.model.mirror(trim, true, false)
      // this.models.trim = makerjs.model.rotate(this.models.trim, 90)
      // this.models.trim.layer = layerNames.engrave.label

      // let trimMeasurements = makerjs.measure.modelExtents(this.models.trim)
      // makerjs.model.moveRelative(this.models.trim, [-trimMeasurements.center[0], -trimMeasurements.center[1]])
      // panel.ventRef === 'l'
      //   ? makerjs.model.moveRelative(this.models.trim, [20, panel.h / 2])
      //   : makerjs.model.moveRelative(this.models.trim, [panel.w - 20, panel.h / 2])
    }

    // rotate to vertical orientation for Plinths, Undersides and Top Panels
    if (['v-plinth', 'plinth', 'top', 'underside', 'shelf'].includes(panel.type)) {
      for (let key in this.models) {
        this.models[key] = makerjs.model.rotate(this.models[key], 90)
        this.models[key] = makerjs.model.moveRelative(this.models[key], [panel.h, 0])
      }
    }
  }

  addVerticalLacquerGuides() {
    this.models.lacquerLine = {
      paths: {
        frontEdge: {
          type: 'line',
          origin: [this.lacquerLineEdgeInset, this.lacquerLineSideInset],
          end: [this.lacquerLineEdgeInset, this.height - this.lacquerLineSideInset]
        },
        backEdge: {
          type: 'line',
          origin: [this.width - this.lacquerLineEdgeInset, this.lacquerLineSideInset],
          end: [this.width - this.lacquerLineEdgeInset, this.height - this.lacquerLineSideInset]
        }
      }
    }
    this.models.lacquerLine.layer = layerNames.cpEngrave.label
  }

  addHorizontalLacquerGuides() {
    this.models.lacquerLine = {
      paths: {
        topEdge: {
          type: 'line',
          origin: [this.lacquerLineSideInset, this.lacquerLineEdgeInset],
          end: [this.width - this.lacquerLineSideInset, this.lacquerLineEdgeInset]
        },
        bottomEdge: {
          type: 'line',
          origin: [this.lacquerLineSideInset, this.height - this.lacquerLineEdgeInset],
          end: [this.width - this.lacquerLineSideInset, this.height - this.lacquerLineEdgeInset]
        }
      }
    }
    this.models.lacquerLine.layer = layerNames.cpEngrave.label
  }

  addNoLacquerMark() {
    this.models.lacquerLine = {
      paths: {
        topEdge: {
          type: 'line',
          origin: [-this.lacquerCrossSize / 2, -this.lacquerCrossSize / 2],
          end: [this.lacquerCrossSize / 2, this.lacquerCrossSize / 2]
        },
        bottomEdge: {
          type: 'line',
          origin: [-this.lacquerCrossSize / 2, this.lacquerCrossSize / 2],
          end: [this.lacquerCrossSize / 2, -this.lacquerCrossSize / 2]
        }
      }
    }
    makerjs.model.moveRelative(this.models.lacquerLine, [this.width / 2, this.height / 2])
    this.models.lacquerLine.layer = layerNames.cpEngrave.label
  }

  createLighting() {
    const lightingWidth = this.panel.lighting + this.lightingPadding
    const lightingOffsetX =
      this.panel.lightingRef == 'r'
        ? this.panel.lightingOffset
        : this.panel.lightingRef == 'l'
        ? this.width - this.panel.lightingOffset - lightingWidth
        : (this.width - lightingWidth) / 2

    this.models.lighting = { models: {} }
    this.models.lighting.models.rebate = new makerjs.models.Rectangle(lightingWidth, this.lightingDepth)
    this.models.lighting.models.rebate.layer = layerNames.mittledGroove.label

    this.models.lighting.models.lines = {
      layer: layerNames.mittledCorner.label,
      paths: {
        line1: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke + 5],
          end: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke]
        },
        line2: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, this.lightingRebateCornerPoke],
          end: [this.lightingRebateCornerPoke + 5, this.lightingRebateCornerPoke]
        },
        line3: {
          type: 'line',
          origin: [-this.lightingRebateCornerPoke + lightingWidth - 5, this.lightingRebateCornerPoke],
          end: [-this.lightingRebateCornerPoke + lightingWidth, this.lightingRebateCornerPoke]
        },
        line4: {
          type: 'line',
          origin: [-this.lightingRebateCornerPoke + lightingWidth, this.lightingRebateCornerPoke],
          end: [-this.lightingRebateCornerPoke + lightingWidth, this.lightingRebateCornerPoke + 5]
        },
        line5: {
          type: 'line',
          origin: [
            -this.lightingRebateCornerPoke + lightingWidth,
            -this.lightingRebateCornerPoke + this.lightingDepth - 5
          ],
          end: [-this.lightingRebateCornerPoke + lightingWidth, -this.lightingRebateCornerPoke + this.lightingDepth]
        },
        line6: {
          type: 'line',
          origin: [-this.lightingRebateCornerPoke + lightingWidth, -this.lightingRebateCornerPoke + this.lightingDepth],
          end: [-this.lightingRebateCornerPoke + lightingWidth - 5, -this.lightingRebateCornerPoke + this.lightingDepth]
        },
        line7: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke + 5, -this.lightingRebateCornerPoke + this.lightingDepth],
          end: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth]
        },
        line8: {
          type: 'line',
          origin: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth],
          end: [this.lightingRebateCornerPoke, -this.lightingRebateCornerPoke + this.lightingDepth - 5]
        }
      }
    }

    makerjs.model.move(this.models.lighting, [lightingOffsetX, this.height / 2 - this.lightingDepth / 2])

    // Cable housing
    let xPos = this.panel.lightingRef == 'l' ? this.lightCableWidth : lightingWidth

    // Channel
    this.models.lighting.models['cable-channel'] = new makerjs.models.RoundRectangle(
      this.lightCableWidth,
      this.height / 2 + this.lightCableWidth / 2 + 8,
      this.lightCableWidth / 2
    )
    makerjs.model.move(this.models.lighting.models['cable-channel'], [
      xPos - this.lightCableWidth,
      this.lightCableWidth / 2
    ])
    this.models.lighting.models['cable-channel'].layer = layerNames.cableChannelGroove.label

    // Channel plug boxes
    let boxPos = this.panel.lightingRef == 'l' ? xPos - 5 - 1 : xPos - 8 + 1
    this.models.lighting.models['cable-channel-plug-box-front'] = new makerjs.models.Rectangle(8, 15.5)
    makerjs.model.move(this.models.lighting.models['cable-channel-plug-box-front'], [boxPos, this.lightingDepth - 1])
    ;('cable-channel-plug-box-front')
    this.models.lighting.models['cable-channel-plug-box-front'].layer = layerNames.cableChannelGroove.label

    //
    this.models.lighting.models['cable-channel-plug-box-back'] = new makerjs.models.Rectangle(8, 15.5)
    makerjs.model.move(this.models.lighting.models['cable-channel-plug-box-back'], [boxPos, this.height / 2 - 4])
    ;('cable-channel-plug-box-back')
    this.models.lighting.models['cable-channel-plug-box-back'].layer = layerNames.cableChannelGroove.label

    // add Cover Channel & Cover Pieces for shelves ONLY
    if (this.panel.type === 'shelf') {
      // Cover Channel
      this.models.lighting.models['cable-cover-channe'] = new makerjs.models.RoundRectangle(
        this.lightCableCoverWidth,
        this.height / 2 + this.lightCableWidth + this.lightCableCoverWidth + 2,
        this.lightCableCoverWidth / 2
      )
      makerjs.model.move(this.models.lighting.models['cable-cover-channe'], [
        xPos - this.lightCableCoverWidth + (this.lightCableCoverWidth - this.lightCableWidth) / 2,
        0
      ])
      this.models.lighting.models['cable-cover-channe'].layer = layerNames.cableCoverGroove.label

      // Cover Piece
      const coverRect = new makerjs.models.Rectangle(
        this.lightCableCoverWidth,
        this.height / 2 + this.lightingDepth / 2 - this.lightCableCoverWidth / 2
      )
      const coverCirc = new makerjs.models.Ellipse(this.lightCableCoverWidth / 2, this.lightCableCoverWidth / 2)
      makerjs.model.move(coverCirc, [5, 0])

      this.models['cable-cover'] = makerjs.model.combine(coverRect, coverCirc, false, true, false, true)
      // this.models['cable-cover'] = makerjs.model.distort(this.models['cable-cover'], 1.05, 1)

      this.models['cable-cover'] = makerjs.layout.cloneToRow(this.models['cable-cover'], 2, 6)
      makerjs.model.move(this.models['cable-cover'], [-100, this.height + 100])

      this.models['cable-cover'].layer = layerNames.cableCoverCutout.label

      // cover piece mill
      const millRect = new makerjs.models.Rectangle(
        this.lightCableCoverWidth * 2 + 6 + 20,
        this.height / 2 + this.lightingDepth / 2 + 8 + 20
      )
      makerjs.model.move(millRect, [
        -(100 - this.lightCableCoverWidth + 20.5),
        this.height - 10 - this.lightCableCoverWidth / 2 + 100
      ])
      this.models['mill'] = millRect
      this.models['mill'].layer = layerNames.millCableCover.label

      // cover piece lip mill
      const lipMillRect = new makerjs.models.Rectangle(
        this.lightCableCoverWidth * 2 + 6 + 20,
        this.lightingDepth + 4 + 20
      )
      makerjs.model.move(lipMillRect, [
        -(100 - this.lightCableCoverWidth + 20.5),
        this.height - 10 - this.lightCableCoverWidth / 2 + 100
      ])
      this.models['lip-mill'] = lipMillRect
      this.models['lip-mill'].layer = layerNames.millCableCoverLip.label
    }

    // add a mega warning
    let text = new makerjs.models.Text(
      this.font,
      'Cable + engrave on back side. Light on (good + flipped) face side.',
      80
    )
    this.models.warningText = makerjs.model.mirror(text, true, false)
    this.models.warningText.layer = layerNames.warning.label

    let textMeasurements = makerjs.measure.modelExtents(this.models.warningText)
    makerjs.model.moveRelative(this.models.warningText, [
      -textMeasurements.center[0] * 2,
      -textMeasurements.center[1] + 800
    ])
  }

  createVent() {
    const ventStartX =
      this.panel.ventRef === 'r'
        ? this.width - this.panel.ventOffset - this.vPlinthGrooveHeight / 2
        : this.panel.ventOffset + this.vPlinthGrooveHeight / 2
    const ventEndX =
      this.panel.ventRef === 'r'
        ? this.width - this.panel.ventOffset - this.panel.vent + this.vPlinthGrooveHeight / 2
        : this.panel.ventOffset + this.panel.vent - this.vPlinthGrooveHeight / 2
    const ventOffsetY = (this.height - (this.numOfVPlinthGrooves - 1) * this.vPlinthGrooveSpacing) / 2
    const botVentGrooveY = ventOffsetY
    const midVentGrooveY = ventOffsetY + this.vPlinthGrooveSpacing
    const topVentGrooveY = ventOffsetY + 2 * this.vPlinthGrooveSpacing
    this.models.vent = { models: {} }
    this.models.vent.models.lines = {
      layer: layerNames.ventLine.label,
      paths: {
        line1: {
          type: 'line',
          origin: [ventStartX, botVentGrooveY],
          end: [ventEndX, botVentGrooveY]
        },
        line2: {
          type: 'line',
          origin: [ventStartX, midVentGrooveY],
          end: [ventEndX, midVentGrooveY]
        },
        line3: {
          type: 'line',
          origin: [ventStartX, topVentGrooveY],
          end: [ventEndX, topVentGrooveY]
        }
      }
    }
  }
}
