const makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'
import cadMethods from '@/js/cad/CadMethods'
import AdjustableShelvingPins from '../features/adjustableShelvingPins'

export default class Fins {
  constructor(
    urtil,
    materialThickness,
    finDepth,
    finInfo,
    backInfo,
    tSlotInfo,
    sideRebateInfo,
    engravingInfo,
    adjShelfInfo
  ) {
    this.models = {}
    // VARS
    this.urtil = urtil
    this.materialThickness = materialThickness
    this.finDepth = finDepth
    this.adjShelfInfo = adjShelfInfo
    this.backInfo = backInfo
    this.finInfo = finInfo
    this.engravingInfo = engravingInfo

    this.finTSlotDepth = finInfo?.tSlotDepth
    this.finHeightLoss = finInfo?.heightLoss

    this.backRebateWidth = backInfo?.rebateWidth
    this.TSlotToolHeight = tSlotInfo?.toolHeight
    this.tSlotTolerance = tSlotInfo?.tolerance

    this.font = engravingInfo?.font
    this.fontSize = engravingInfo?.fontSize
    this.orientationLabel = engravingInfo?.orientationLabel

    // CALCS
    this.finHeight = urtil.h - materialThickness * 2 + finInfo?.tSlotDepth * 2
    this.slotHeight = finDepth / 2 + 6
    this.fullTSlotHeight = materialThickness + tSlotInfo?.tolerance
    this.sideRebateWidth = materialThickness + sideRebateInfo?.tolerance
    this.finToothSetback = 6.5
    this.numOfDividers = urtil.compartments - 1

    for (let i = 0; i < this.numOfDividers; i++) {
      this.createFins(i + 1)
    }
  }

  createFins(copy) {
    let fin = (this.models['fin' + copy] = { models: {} })

    fin.models.outline = {
      layer: layerNames.cutout.label,
      paths: {
        line1: {
          type: 'line',
          origin: [0, 0],
          end: [this.finHeight, 0]
        },
        line2: {
          type: 'line',
          origin: [this.finHeight, 0],
          end: [this.finHeight - this.finHeightLoss, this.finDepth - this.finToothSetback]
        },
        line3: {
          type: 'line',
          origin: [this.finHeight - this.finHeightLoss, this.finDepth - this.finToothSetback],
          end: [this.finHeight - this.finTSlotDepth, this.finDepth - this.finToothSetback]
        },
        line4: {
          type: 'line',
          origin: [this.finHeight - this.finTSlotDepth, this.finDepth - this.finToothSetback],
          end: [this.finHeight - this.finTSlotDepth, this.finDepth]
        },

        line5: {
          type: 'line',
          origin: [this.finHeight - this.finTSlotDepth, this.finDepth],
          end: [this.finTSlotDepth, this.finDepth]
        },
        line6: {
          type: 'line',
          origin: [this.finTSlotDepth, this.finDepth],
          end: [this.finTSlotDepth, this.finDepth - this.finToothSetback]
        },
        line7: {
          type: 'line',
          origin: [this.finTSlotDepth, this.finDepth - this.finToothSetback],
          end: [this.finHeightLoss, this.finDepth - this.finToothSetback]
        },
        line8: {
          type: 'line',
          origin: [this.finHeightLoss, this.finDepth - this.finToothSetback],
          end: [0, 0]
        }
      }
    }
    makerjs.model.move(fin.models.outline, [-this.finHeight - 50, 0])
    fin.models.outline.layer = layerNames.cutout.label

    // If lighting
    if (this.urtil.lighting) {
      const lightingNotchDepth = 11
      const lightingNotchHeight = 31
      fin.models.finLightingNotch = new makerjs.models.Rectangle(lightingNotchDepth, lightingNotchHeight)
      makerjs.model.move(fin.models.finLightingNotch, [
        -50 - lightingNotchDepth + 1,
        this.urtil.d / 2 - this.backRebateWidth - lightingNotchHeight / 2
      ])
      fin.models.finLightingNotch.layer = layerNames.finNotch.label

      fin.models.finLightingNotchClone = makerjs.cloneObject(fin.models.finLightingNotch)
      makerjs.model.moveRelative(fin.models.finLightingNotchClone, [-this.finHeight + lightingNotchDepth - 2, 0])
    }

    fin.models.finNotchA = new makerjs.models.Rectangle(5, 21)
    makerjs.model.move(fin.models.finNotchA, [-55 - this.TSlotToolHeight, this.finDepth - 18.5])

    fin.models.finNotchB = new makerjs.models.Rectangle(5, 21)
    makerjs.model.move(fin.models.finNotchB, [-50 - this.finHeight + this.TSlotToolHeight, this.finDepth - 18.5])

    fin.models.finNotchA.layer = layerNames.finNotch.label
    fin.models.finNotchB.layer = layerNames.finNotch.label

    // making vertical fin with shelf slots
    if (this.urtil.shelving === 'fixed' && this.urtil.shelvingOptions?.heights?.length > 0) {
      const shelfPositionArr = cadMethods.calculateShelfHeights(this.urtil, this.fullTSlotHeight, this.sideRebateWidth)

      shelfPositionArr.forEach((shelfPos, index) => {
        let slot = (fin.models['slot-' + index] = new makerjs.models.Rectangle(
          this.fullTSlotHeight,
          this.slotHeight + 1
        ))
        makerjs.model.move(slot, [-50 - shelfPos - (this.finTSlotDepth + this.tSlotTolerance), -1])
        fin.models.outline = makerjs.model.combine(fin.models.outline, slot, false, true, true, false)
        fin.models.outline.layer = layerNames.cutout.label
        delete fin.models['slot-' + index]
      })
    }
    // making vertical fin with adjustable pin holes
    else if (this.urtil.shelving === 'adjustable' && this.urtil.shelvingOptions.qty > 0) {
      fin.models.pinHoles = new AdjustableShelvingPins(
        this.urtil,
        this.materialThickness,
        this.finDepth,
        this.adjShelfInfo,
        this.backInfo,
        this.finInfo,
        'fins'
      )
    }

    // add top-orientate engraving to all fins
    const finEngrave = new makerjs.models.Text(
      this.font,
      (this.orientationLabel + ' - ' + this.engravingInfo.label).toUpperCase(),
      this.fontSize * 0.6
    )
    this.models.finEngrave = makerjs.model.mirror(finEngrave, true, false)
    this.models.finEngrave.layer = layerNames.engrave.label
    makerjs.model.move(this.models.finEngrave, [-30 - this.finTSlotDepth + 5, this.finDepth * 0.8])
    makerjs.model.rotate(this.models.finEngrave, -90, [-50 - this.finTSlotDepth + 5, this.finDepth * 0.8])

    // add a mega warning
    if (copy === 1) {
      let text = new makerjs.models.Text(this.font, 'Set T-SLOT start point and check clash with cable groove!', 200)
      this.models.warningText = makerjs.model.mirror(text, true, false)
      this.models.warningText.layer = layerNames.warning.label

      let textMeasurements = makerjs.measure.modelExtents(this.models.warningText)
      makerjs.model.moveRelative(this.models.warningText, [
        -textMeasurements.center[0],
        -textMeasurements.center[1] + 1000
      ])
    }

    // final position
    if (copy === 1) {
      makerjs.model.move(fin, [0, 0])
    } else if (copy === 2) {
      makerjs.model.move(fin, [0, this.urtil.d + 50])
    } else if (copy % 2 === 0) {
      makerjs.model.move(fin, [-(this.finHeight + 50) * ((copy + 2) / 2 - 2), this.urtil.d + 50])
    } else {
      makerjs.model.move(fin, [-(this.finHeight + 50) * ((copy + 1) / 2 - 1), 0])
    }
  }
}
