<template>
  <div>
    <v-overlay v-if="loading" color="primary" :opacity="0.9" class="text-center"
      ><v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div>
      <div id="view-sub-nav">
        <v-switch
          color="primary"
          :label="usInvoices ? 'US Invoices' : 'UK Invoices'"
          @change="
            () => {
              usInvoices = !usInvoices
            }
          "
          hide-details
          class="my-0 py-0 ml-6"
        ></v-switch>
        <DateRangePicker class="ml-6" :empty="true" :short="true" @change="dateRangeChanged"></DateRangePicker>
        <v-spacer></v-spacer>
        <v-btn
          class="ml-3"
          v-if="usInvoices"
          :disabled="this.selected.length !== 1"
          depressed
          outlined
          color="primary"
          @click="generateSalesTax"
          >Generate Missing Sales Tax Data</v-btn
        >
        <v-btn
          class="ml-3"
          :disabled="this.selected.length !== 1"
          depressed
          outlined
          color="primary"
          @click="singleInvoice"
          >Generate Invoice</v-btn
        >
        <v-btn
          class="ml-3"
          :disabled="this.selected.length !== 1"
          depressed
          outlined
          color="primary"
          @click="deleteContactId"
          >Delete Contact ID</v-btn
        >
        <v-btn
          class="ml-3"
          :disabled="this.selected.length !== 1"
          depressed
          outlined
          color="primary"
          @click="
            () => {
              addInvoiceDialog = true
              invoice = {}
            }
          "
          >Add Missing Invoice Data</v-btn
        >
      </div>
      <v-data-table
        v-model="selected"
        :items="usInvoices ? usMissingInvoices : ukMissingInvoices"
        :headers="usInvoices ? usHeaders : ukHeaders"
        single-select
        show-select
      >
        <template #item.id="{item}">
          {{ item.id || '-' }}
        </template>
        <template #item.orderNumber="{item}">
          {{ item.orderNumber || '-' }}
        </template>
        <template #item.type="{item}">
          {{ item.type }}
        </template>
        <template #item.customerEmail="{item}">
          {{ item.customerEmail || '-' }}
        </template>
        <template v-if="!usInvoices" #item.dateScheduled="{item}">
          {{ $utils.convertDate(item.dateScheduled) || '-' }}
        </template>
        <template v-if="usInvoices" #item.dispatchEta="{item}">
          {{ $utils.convertDate(item.dispatchEta) || '-' }}
        </template>
        <template #item.paidTotal="{item}">
          {{ $numeral(item.paidTotal).format('0,0.00') || '-' }}
        </template>
        <template #item.orderTotal="{item}">
          {{ $numeral(item.orderTotal).format('0,0.00') || '-' }}
        </template>
        <template v-if="usInvoices" #item.salesTax="{item}">
          <div
            class="text-center"
            :class="item.salesTax == undefined ? 'red--text' : ''"
            :style="item.salesTax == undefined ? 'border: 2px solid red;' : ''"
          >
            {{ item.salesTax != undefined ? $numeral(item.salesTax).format('0,0.00') : 'Missing Tax' }}
          </div>
        </template>
        <template #item.balanceDue="{item}">
          {{ item.balanceDue == 0 ? 'Paid' : item.balanceDue }}
        </template>
        <template #item.actions="{item}" class="d-flex justify-end align-center">
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-icon small @click="viewQuote(item)" v-on="on">mdi-clipboard-text</v-icon>
            </template>
            <span>Original Quote</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <div class="text-center">
      <v-snackbar color="bgGrey" v-model="responseSnackbar" vertical top :timeout="-1">
        <div class="text-h6 pb-4">{{ responseMessage.message }}</div>
        <div v-if="responseMessage.xeroInvoiceID">
          <p class="text-body-1 font-weight-bold red--text">Please check xero links:</p>
          <p>Xero Invoice ID: {{ responseMessage.xeroInvoiceID }}</p>
          <p>Xero Invoice Number: {{ responseMessage.xeroInvoiceNumber }}</p>
          <p>
            Xero Invoice URL:
            <a class="mt-4 info--text" :href="responseMessage.xeroInvoiceURL" target="_blank">{{
              responseMessage.xeroInvoiceURL
            }}</a>
          </p>
        </div>
        <div v-else-if="responseMessage.error">
          <p class="text-body-1 font-weight-bold red--text">Error:</p>
          <p>{{ responseMessage.error }}</p>
        </div>

        <template>
          <v-btn class="float-right" color="red" variant="text" @click="responseSnackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-dialog v-model="addInvoiceDialog" width="800"
      ><v-card>
        <v-card-title>Add Invoice Data</v-card-title>
        <v-card-text>
          <v-text-field v-model="invoice.id" label="Xero Invoice ID (shown in title of preview)"></v-text-field>
          <v-text-field v-model="invoice.number" label="Xero Invoice Number (include 'INV-')"></v-text-field>
          <v-text-field v-model="invoice.url" label="Xero Invoice URL (under options, get link)"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="addInvoiceData">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Component Overlay Dialog -->
    <v-dialog v-model="quoteDialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary" class="no-print elevation-0">
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="
              () => {
                quoteDialog = false
                overlayComponent = null
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <component
          :is="overlayComponent"
          :shipment="selectedOrder"
          :quoteID="selectedOrder.quoteID"
          :versionID="selectedOrder.versionID"
        ></component>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import QuoteEdit from '@/views/QuoteEdit'
import DateRangePicker from '@/components/ui/DateRangePicker'

export default {
  name: 'Invoicing',
  components: {
    QuoteEdit,
    DateRangePicker
  },
  data() {
    return {
      activeDateRange: null,
      selected: [],
      loading: false,
      missingInvoices: [],
      ukHeaders: [
        {
          text: 'ID',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Order #',
          sortable: false,
          value: 'orderNumber'
        },
        {
          text: 'Type',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Customer Email',
          sortable: false,
          value: 'customerEmail'
        },
        {
          text: 'Date Scheduled',
          sortable: true,
          value: 'dateScheduled'
        },
        {
          text: 'Paid Total £',
          sortable: false,
          value: 'paidTotal'
        },
        {
          text: 'Order Total £',
          sortable: false,
          value: 'orderTotal'
        },
        {
          text: 'Balance Due £',
          sortable: false,
          value: 'balanceDue'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          width: 160,
          value: 'actions'
        }
      ],
      usHeaders: [
        {
          text: 'ID',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Order #',
          sortable: false,
          value: 'orderNumber'
        },
        {
          text: 'Type',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Customer Email',
          sortable: false,
          value: 'customerEmail'
        },
        {
          text: 'Dispatch ETA',
          sortable: true,
          value: 'dispatchEta'
        },
        {
          text: 'Paid Total $',
          sortable: false,
          value: 'paidTotal'
        },
        {
          text: 'Order Total $',
          sortable: false,
          value: 'orderTotal'
        },
        {
          text: 'Sales Tax $',
          sortable: false,
          value: 'salesTax'
        },
        {
          text: 'Balance Due $',
          sortable: false,
          value: 'balanceDue'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          width: 160,
          value: 'actions'
        }
      ],
      addInvoiceDialog: false,
      invoice: {},
      usInvoices: false,
      selectedOrder: {},
      quoteDialog: false,
      dialogTitle: null,
      overlayComponent: null,
      responseSnackbar: false,
      responseMessage: {
        message: '',
        xeroInvoiceID: '',
        xeroInvoiceNumber: '',
        xeroInvoiceURL: ''
      }
    }
  },
  computed: {
    usMissingInvoices() {
      return this.missingInvoices.filter(order => order.region === 'us' && this.usInvoices)
    },
    ukMissingInvoices() {
      return this.missingInvoices.filter(order => order.region === 'uk' && !this.usInvoices)
    }
  },
  methods: {
    dateRangeChanged(range) {
      this.activeDateRange = range

      this.loadData()
    },
    async loadData() {
      this.loading = true
      this.selected = []
      this.missingInvoices = [] // resets the array on reload

      // set orderSnap depending on date range
      const orderSnap = await this.$db
        .collection('orders')
        .where('foc', '==', false)
        .where('dateCreated', '>=', this.activeDateRange[0].startOf('day').toDate())
        .where('dateCreated', '<=', this.activeDateRange[1].endOf('day').toDate())
        .get()

      for (const order of orderSnap.docs) {
        // check if paid for and doesnt have xero invoice
        if (order.data().payments.length > 0 && !order.data()?.xeroInvoiceNumber) {
          let orderObj
          // checking if its shipped/dispatched
          if (order.data().type === 'sample-pack') {
            if (order.data().dispatched) {
              orderObj = { ...order.data(), id: order.id }
              this.missingInvoices.push(orderObj)
              continue
            }
          } else if (order.data().type === 'mega-admin') {
            if (order.data().region === 'us') {
              if (order.data().shipped) {
                orderObj = { ...order.data(), id: order.id }
                this.missingInvoices.push(orderObj)
                continue
              }
            } else if (order.data().region === 'uk') {
              // uk shipped info is in shipment
              // also add dateScheduled from shipment
              let shipped = true
              let dateScheduled
              const shipmentsSnap = await this.$db
                .collection('shipments')
                .where('orderID', '==', order.id)
                .get()
              for (const shipment of shipmentsSnap.docs) {
                if (shipment.data().shipped === false) shipped = false
                if (shipment.data().dateScheduled) {
                  dateScheduled = shipment.data().dateScheduled
                }
              }
              orderObj = { ...order.data(), dateScheduled, id: order.id }
              if (shipped) {
                this.missingInvoices.push(orderObj)
                continue
              } else {
                continue
              }
            }
          }
        }
      }

      // add cost/paid/balance info
      for (const orderObj of this.missingInvoices) {
        // add paid total
        let paidTotal = 0
        for (const payment of orderObj.payments) {
          paidTotal += payment.amount
        }
        orderObj.paidTotal = paidTotal

        // calc order total
        if (orderObj.region === 'us') {
          // add sales tax
          const quoteSnap = await this.$db
            .collection('quotes')
            .doc(orderObj.quoteID)
            .get()

          const version = quoteSnap.data().versions.find(version => {
            return version.id === orderObj.versionID
          })
          if (version.pricing?.salesTax) {
            orderObj.salesTax = version.pricing?.salesTax?.amount_to_collect
              ? version.pricing?.salesTax?.amount_to_collect
              : version.pricing?.salesTax?.rate
          } else {
            orderObj.salesTax = undefined
          }
          // order total with sales tax
          orderObj.orderTotal =
            orderObj.salesTax != undefined && orderObj.salesTax > 0
              ? orderObj.total + orderObj.salesTax
              : orderObj.total
        } else if (orderObj.region === 'uk') {
          // order total is just the total
          orderObj.orderTotal = orderObj.total
        }

        // add balance due
        if (orderObj.balanceOutstanding) {
          orderObj.balanceDue = orderObj.orderTotal.toFixed(2) - orderObj.paidTotal.toFixed(2)
        } else {
          orderObj.balanceDue = 0
        }
      }

      this.loading = false
    },

    async addInvoiceData() {
      this.addInvoiceDialog = false
      this.loading = true
      const selectedOrder = await this.$db
        .collection('orders')
        .doc(this.selected[0].id)
        .get()

      // check if there isnt already an xero link
      if (
        selectedOrder.data()?.xeroInvoiceNumber ||
        selectedOrder.data()?.xeroInvoiceID ||
        selectedOrder.data()?.xeroInvoiceURL
      ) {
        this.responseMessage.message = 'Invoice already exists'
        this.responseMessage.xeroInvoiceID = selectedOrder.data().xeroInvoiceID
        this.responseMessage.xeroInvoiceNumber = selectedOrder.data().xeroInvoiceNumber
        this.responseMessage.xeroInvoiceURL = selectedOrder.data().xeroInvoiceURL

        this.loading = false
        this.responseSnackbar = true
      } else {
        this.invoice = {
          id: this.invoice.id,
          number: this.invoice.number,
          url: this.invoice.url
        }
        await this.$db
          .collection('orders')
          .doc(this.selected[0].id)
          .update({
            xeroInvoiceID: this.invoice.id,
            xeroInvoiceNumber: this.invoice.number,
            xeroInvoiceURL: this.invoice.url
          })
        this.responseMessage.message = 'Invoice data added successfully'
        this.responseMessage.xeroInvoiceID = this.invoice.id
        this.responseMessage.xeroInvoiceNumber = this.invoice.number
        this.responseMessage.xeroInvoiceURL = this.invoice.url

        this.loadData()
        this.loading = false
        this.responseSnackbar = true
      }
    },
    async singleInvoice() {
      this.loading = true
      try {
        let orderId
        for (const order of this.selected) {
          orderId = order.id
        }
        const singleInvoiceFunc = this.$firebase.functions().httpsCallable('manualSingleInvoice')
        const invoiceResult = await singleInvoiceFunc(orderId)
        if (invoiceResult && invoiceResult.data.xeroInvoiceID) {
          this.responseMessage.message = 'Invoices generated successfully'
          this.responseMessage.xeroInvoiceID = invoiceResult.data.xeroInvoiceID
          this.responseMessage.xeroInvoiceNumber = invoiceResult.data.xeroInvoiceNumber
          this.responseMessage.xeroInvoiceURL = invoiceResult.data.xeroInvoiceURL

          this.loadData()
        } else {
          this.responseMessage.message = 'Error generating invoices'
          this.responseMessage.error = invoiceResult.data.error
        }
      } catch (error) {
        console.log(error)
        this.responseMessage.message = 'Error generating invoices'
        this.responseMessage.error = error.message
      }
      this.loading = false
      this.responseSnackbar = true
    },
    async deleteContactId() {
      this.loading = true
      try {
        const selectedOrder = await this.$db
          .collection('orders')
          .doc(this.selected[0].id)
          .get()
        let contactEmail = selectedOrder.data().customerEmail

        const userSnap = await this.$db
          .collection('users')
          .where('email', '==', contactEmail)
          .get()

        if (userSnap.docs.length > 0) {
          await userSnap.docs[0].ref.update({
            xeroContactID: ''
          })
          this.responseMessage.message = 'Contact ID deleted successfully'
        }
      } catch (error) {
        this.responseMessage.message = 'Error deleting contact ID'
        this.responseMessage.error = error.message
      }
      this.loading = false
      this.responseSnackbar = true
    },
    async generateSalesTax() {
      try {
        this.loading = true

        for (const order of this.selected) {
          if (order.region === 'us') {
            const quoteSnap = await this.$db
              .collection('quotes')
              .doc(order.quoteID)
              .get()

            let quoteData = quoteSnap.data()

            const version = quoteData.versions.find(v => {
              return v.id === order.versionID
            })

            if (!version.pricing?.salesTax) {
              version.pricing = {
                ...version.pricing,
                salesTax: {
                  rate: 0
                }
              }
            }

            let doc = this.$db.collection('quotes').doc(order.quoteID)
            await doc.set(quoteData)

            const calculateSalesTax = this.$firebase.functions().httpsCallable('calculateSalesTax')
            const salesTax = await calculateSalesTax({
              to_zip: order.customerContactDetails.deliveryPostcode,
              to_state: order.customerContactDetails.deliveryState,
              amount: version.pricing.total - version.pricing.shipping,
              shipping: version.pricing.shipping,
              line_items: [
                {
                  id: '1',
                  quantity: 1,
                  unit_price: version.pricing.total - version.pricing.shipping,
                  discount: 0
                }
              ]
            })

            version.pricing.salesTax = salesTax.data

            await doc.set(quoteData)
            await this.loadData()
          }
        }

        this.loading = false
        return true
      } catch (error) {
        //this.salesTaxError = error.message
        this.loading = false
        return false
      }
    },
    async viewQuote(orderObj) {
      if (this.usInvoices && orderObj.region == 'us') {
        const orderDoc = await this.$db
          .collection('orders')
          .doc(orderObj.id)
          .get()

        this.quoteDialog = true
        this.selectedOrder = orderDoc.data()
        this.dialogTitle = orderDoc.data().customerEmail
        this.overlayComponent = 'QuoteEdit'
      } else if (!this.usInvoices && orderObj.region == 'uk') {
        const shipmentSnap = await this.$db
          .collection('shipments')
          .where('orderID', '==', orderObj.id)
          .get()

        this.quoteDialog = true
        this.selectedOrder = shipmentSnap.docs[0].data()
        this.dialogTitle = shipmentSnap.docs[0].data().customerEmail
        this.overlayComponent = 'QuoteEdit'
      }
    }
  }
}
</script>
